import React from "react";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import {
  IconButton,
  Link as MaterialLink,
  LinearProgress,
} from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import { FormErrors } from "../../../components/Login/FormErrors";
import { userService } from "_services/user.service";
import enMsg from "__helpers/locale/en/en";
import PhoneNumber from "awesome-phonenumber";
import ConfirmModal from "components/Modal/modal";
import Icon from "@material-ui/core/Icon";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import Tooltip from "@material-ui/core/Tooltip";
import { apiPath } from "api";
import {
  generalAuthTokenHeader,
  multimediaAuthTokenHeader,
} from "__helpers/auth-header";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableSortLabel,
  TablePagination,
} from "@material-ui/core";
import { DEFAULT_PER_PAGE_RECORDS, REFERRAL_STATUS } from "__helpers/constants";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Slide,
} from "@material-ui/core";
import SelectStatus from "components/SelectStatus/selectStatus";
import CircularProgress from "@material-ui/core/CircularProgress";
import { basePath, baseRoutes, dpBaseRoutes } from "base-routes";
import { FormGroup, FormControl, InputLabel } from "@material-ui/core";
import { Link } from "react-router-dom";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import { PATIENT_SORT, PATIENT_FILTER } from "__helpers/constants";
import done from "assets/img/done.svg";

import Container from "@material-ui/core/Container";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Checkbox from "@material-ui/core/Checkbox";
import logo from "assets/img/front-logo.png";
import ScrollMenu from "react-horizontal-scrolling-menu";
import InfiniteScroll from "react-infinite-scroller";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { connect } from "react-redux";
import { reduxLoad } from "js/actions/index";

import Chip from "@material-ui/core/Chip";
import CircleChecked from "@material-ui/icons/CheckCircleOutline";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";

import leftArrow from "assets/img/icons/arrorw1.svg";
import rightArrow from "assets/img/icons/arrow2.svg";
import { ALLOWED_PROFILE_IMAGE_SIZE } from "__helpers/constants";
import { ALLOWED_IMAGE_EXTENTION } from "__helpers/constants";
import fileExtension from "file-extension";
import infoIcon from "assets/img/icons/info-icon.svg";
import tasksImg from "assets/img/icons/tasksImg.png";
import { REDUX_STATE_DATA } from "__helpers/constants";

// own carosoul
// import React from 'react';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { PATIENT_LIST, MAX_INPUT_LENGTH_LONG } from "__helpers/constants";
import { USER_INFO } from "__helpers/constants";
import ChangePassword from "views/Profile/ChangePassword";
import { Scrollbars } from "react-custom-scrollbars";
import { formatDateToLocalFormat } from "__helpers/util";
import ChatBubbleOutlineRoundedIcon from "@material-ui/icons/ChatBubbleOutlineRounded";
import Grid from "@material-ui/core/Grid";
import commentIcon from "assets/img/icons/comment.svg";

import ProviderCommentModal from "views/providerViews/PatientDashboard/providerCommentModal";
import update from "immutability-helper"; // ES6

import moment from "moment-timezone";
import BAAComponent from "views/CmsPages/BAAComponent";
import TermsComponent from "views/CmsPages/TermsComponent";
import { providerBaseRoutes } from "base-routes";
import { REFERRAL_TYPE } from "__helpers/constants";
import { REFERRAL_TYPES } from "__helpers/constants";

function mapDispatchToProps(dispatch) {
  return {
    reduxLoad: (reduxLoadVal) => dispatch(reduxLoad(reduxLoadVal)),
  };
}
const mapStateToProps = (state) => {
  return {
    userInfoRedux: state.userInfo,
    categoryListRedux: state.categoryList,
    insuranceListRedux: state.insuranceList,
    patientStatusListRedux: state.patientStatusList,
    hospitalListRedux: state.hospitalList,
    timestampRedux: state.timestamp,
    reduxLoadFlag: state.reduxLoadFlag,
  };
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});
const AddOrRemove ="Add";

moment.tz.setDefault("UTC");
const CategotyMenuItem = ({ keyVals, cListVals }) => {
  const { masterCategories, catagoryId, status } = cListVals;
  return (
    <Box className="item menu-item" key={keyVals} border={1}>
      <p className="category-value">{masterCategories.categoryValue}</p>
      <p className="category-status">{masterCategories.status}Awaiting</p>
    </Box>
  );
};
export const CategotyMenu = (list, selected, categoryList) =>
  list.map((el, key) => {
    const { name } = el;

    return (
      <CategotyMenuItem
        cListVals={el}
        text={name}
        key={key}
        selected={selected}
        categoryList={categoryList}
      />
    );
  });

const CategotyArrow = ({ text, className }) => {
  return (
    <div className={className}>
      <img src={text} />
    </div>
  );
};

const ArrowLeft = CategotyArrow({ text: leftArrow, className: "arrow-prev" });
const ArrowRight = CategotyArrow({ text: rightArrow, className: "arrow-next" });

const selected = "item1";
const options = {
  items: 3,
  nav: true,
  rewind: true,
  autoplay: false,
};

const date = new Date();
const offset = date.getTimezoneOffset();

const getFormattedAddress = (address) => {
  if (address) {
    const addressObject = JSON.parse(address);
    return addressObject.formatted_address || addressObject.address;
  }
};
class PatientListClass extends React.Component {
  inputOpenImageFileRef = React.createRef(null);
  inputOpenFaceSheetFileRef = React.createRef(null);
  maxDate = new Date();
  minDate = new Date();
  constructor(props) {
    super(props);
    let spinner = document.getElementById("loadingSpinner");
    // let patientList = JSON.parse(localStorage.getItem(PATIENT_LIST));
    const patientCategoryList = ["1", "3"];
    this.dateUtility = new DateFnsUtils();
    this.patientImageName = "";
    this.patientImageBlob = null;
    this.patientFaceSheetName = "";
    this.patientFaceSheetBlob = null;
    this.state = {
      loading: false,
      referralActionModalStatus: false,
      acceptedReferralModalStatus: false,
      providerCommentModalStatus: false,
      perPage: DEFAULT_PER_PAGE_RECORDS,
      total: 0,
      selectionCount: 0,
      currentPage: 0,
      totalPatientShowing: 0,
      sorting: "desc",
      filter: "createdDateTime",
      loadingPatient: true,
      loadingState: false,
      items: 10,
      patientSearchKeyword: "",
      direction: true, //true means asc and false means desc
      spinner: spinner,
      declineReason: "",
      formErrors: {
        declineReason: "",
        commentToDP: "",
      },
      declineReasonValid: false,
      patientName: "unknown",
      selectedPatientId: "",
      patientCategoryList: [],
      patientList: [],
      categoryList: props.categoryListRedux ? props.categoryListRedux : [],
      insuranceList: props.insuranceListRedux ? props.insuranceListRedux : [],
      hospitalList: props.hospitalListRedux ? props.hospitalListRedux : [],
      patientStatusList: props.patientStatusListRedux
        ? props.patientStatusListRedux
        : [],
      userInfo: props.userInfoRedux ? props.userInfoRedux : {},
      loadMore: false,
      estDate: null,
      patientCategories: [],
      reduxLoadFlag: false,
      patientTaskCount: 0,
      loadPatient: true,
      selectedPatientData: "",
      selectedPatientCategoryValue: "",
      selectedPatientHospitalValue: "",
      selectedPatientInsuranceValue: "",
      referralStatusIds: [1, 3, 5, 6, 7],
      confirmModalStatus: false,
      confirmModalMessage: "",
      openModal: false,
      bbaCondition: false,
      termNcondition: false,
      searchCriteria: "",
      patientListSeletedIndex: "",
      selectRererralId: "",
      selectRererralItemId: "",
      commentToDP: "",
      commentToDPValid: "",
      isPrelimCommentDone: false,
      prelimCommentByProvider: "",
      commentPostReferralId: "",
      commentsThread: [],
      selectedPatientKey: "",
      confirmBoxStatus: false,
      filterList: [],
      filterby: "",
      filterbyinsuranceId: "",
      selectedcomment: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fetchPatients = this.fetchPatients.bind(this);
    this.search = this.search.bind(this);
    this.searchReturn = this.searchReturn.bind(this);
    this.loadMorePatient = this.loadMorePatient.bind(this);
    this.handlePatientSorting = this.handlePatientSorting.bind(this);

    this.getPatientHospitalName = this.getPatientHospitalName.bind(this);
    this.declineFormSubmit = this.declineFormSubmit.bind(this);
    this.acceptReferral = this.acceptReferral.bind(this);
    this.handleBAAAgree = this.handleBAAAgree.bind(this);
    this.handleTNCAgree = this.handleTNCAgree.bind(this);
    this.fetchFilter = this.fetchFilter.bind(this);
  }
  componentDidMount() {
    this.props.reduxLoad(false);
    let user = JSON.parse(localStorage.getItem(USER_INFO));
    if (!user.termNcondition) {
      this.setState({
        openModal: false,
        termNcondition: true,
        bbaCondition: false,
      });
    } else if (!user.termNcondition) {
      this.setState({
        openModal: false,
        termNcondition: false,
        bbaCondition: true,
      });
    } else {
      if (user.newUser) {
        this.setState({
          openModal: true,
          termNcondition: false,
          bbaCondition: false,
        });
      } else {
        this.setState({
          openModal: false,
          termNcondition: false,
          bbaCondition: false,
        });
      }
    }
    if (this.state.patientList && this.state.patientList.length == 0) {
      this.fetchPatients();
    }

    if (user.bbaCondition && user.termNcondition) {
      console.log("ccc");
      this.fetchPage();
      this.fetchFilter();
    }

    var p = [-10, 200, 100000, -50, 5000];
  }
  handleBAAAgree() {
    let user = JSON.parse(localStorage.getItem(USER_INFO));
    user.termNcondition = true;
    user.bbaCondition = true;
    localStorage.setItem(USER_INFO, JSON.stringify(user));
    if (user.newUser) {
      this.setState({
        openModal: true,
        termNcondition: false,
        bbaCondition: false,
      });
    } else {
      this.setState({
        openModal: false,
        termNcondition: false,
        bbaCondition: false,
      });
    }
  }
  handleTNCAgree() {
    this.setState({
      openModal: false,
      termNcondition: false,
      bbaCondition: true,
    });
  }
  componentDidUpdate() {
    if (
      this.props.reduxLoadFlag != undefined &&
      this.state.reduxLoadFlag != this.props.reduxLoadFlag &&
      this.props.hospitalListRedux &&
      this.props.hospitalListRedux.length > 0
    ) {
      let categoryList = [];
      let insuranceList = [];
      let hospitalList = [];
      let patientStatusList = [];
      let userInfo = {};
      if (this.props.categoryListRedux) {
        let selectedcategoryList = this.props.categoryListRedux;
        categoryList = selectedcategoryList ? selectedcategoryList : [];
      }
      if (this.props.insuranceListRedux) {
        let selectedinsuranceList = this.props.insuranceListRedux;
        insuranceList = selectedinsuranceList ? selectedinsuranceList : [];
      }

      if (this.props.hospitalListRedux) {
        let selectedhospitalList = this.props.hospitalListRedux;
        hospitalList = selectedhospitalList ? selectedhospitalList : [];
      }
      if (this.props.patientStatusListRedux) {
        let selectedpatientStatusList = this.props.patientStatusListRedux;
        patientStatusList = selectedpatientStatusList
          ? selectedpatientStatusList
          : [];
      }
      if (this.props.userInfoRedux) {
        let userInfoRedux = this.props.userInfoRedux;
        userInfo = userInfoRedux ? userInfoRedux : {};
      }
      this.setState({
        reduxLoadFlag: this.props.reduxLoadFlag,
        categoryList: categoryList,
        insuranceList: insuranceList,
        hospitalList: hospitalList,
        patientStatusList: patientStatusList,
        userInfo: userInfo,
      });
    }
  }
  componentWillUnmount() {
    Object.getPrototypeOf(this).constructor.STATE = this.state;
  }
  async fetchPage() {
    
    let showNotification = {};
    let apiUrl = "";
    apiUrl = apiPath.providerPageStageList;

    const response = await fetch(apiUrl, {
      method: "GET",
      headers: generalAuthTokenHeader(),
    })
      .then((response) => {
        console.log(response);
        if (response.status === 400) {
          showNotification = {
            title: "Facility",
            message: "Bad response from server",
            type: "danger",
          };
        } else if (response.ok) {
        } else if (response.status === 401) {
        } else {
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          if (data.orgTab == false) {
            window.location.replace(
              basePath + providerBaseRoutes.profile.path + "?tab=orginational"
            );
          } else if (data.subscriptionTab == false) {
            window.location.replace(
              basePath + providerBaseRoutes.profile.path + "?tab=billing"
            );
          } else if (data.patientCriteria == false) {
            window.location.replace(
              basePath + providerBaseRoutes.profile.path + "?tab=criteria"
            );
          }

          console.log(data.orgTab, "xcc");
        }
      })
      .catch((error) => {
        showNotification = {
          title: "Facility",
          message: "Bad response from server",
          type: "danger",
        };
      });
  }
  async fetchFilter() {
    let showNotification = {};
    let apiUrl = "";
    apiUrl = apiPath.getProviderFilterList;
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: generalAuthTokenHeader(),
    })
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: "Facility",
            message: "Bad response from server",
            type: "danger",
          };
        } else if (response.ok) {
        } else if (response.status === 401) {
        } else {
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          this.setState({
            filterList: data,
          });
        }
      })
      .catch((error) => {
        showNotification = {
          title: "Facility",
          message: "Bad response from server",
          type: "danger",
        };
      });
  }
  async fetchPatients(isDeclineOrAccept) {
    this.setState({
      loadingPatient: true,
    });
    let showNotification = {};
    let apiUrl = "";
    let searchKeyword = this.state.patientSearchKeyword;
    let referralType = localStorage.getItem("selectedReferral")
      ? localStorage.getItem("selectedReferral") == "all"
        ? "&showAll=true"
        : ""
      : "";
    console.log(referralType);
    if (searchKeyword && searchKeyword.length > 0) {
      apiUrl =
        apiPath.providerReferralList +
        "?page=" +
        this.state.currentPage +
        referralType +
        "&size=" +
        this.state.perPage +
        "&filterBy=" +
        (this.state.filterby && this.state.filterby != ""
          ? this.state.filterby
          : "") +
        "&insuranceId=" +
        this.state.filterbyinsuranceId +
        "&sort=" +
        (this.state.filter && this.state.filter != ""
          ? this.state.filter
          : "createdDate") +
        "," +
        (this.state.sorting && this.state.sorting != ""
          ? this.state.sorting
          : "desc") +
        "&search=" +
        searchKeyword;
    } else {
      console.log(referralType);
      apiUrl =
        apiPath.providerReferralList +
        "?page=" +
        this.state.currentPage +
        referralType +
        "&size=" +
        this.state.perPage +
        "&filterBy=" +
        (this.state.filterby && this.state.filterby != ""
          ? this.state.filterby
          : "") +
        "&insuranceId=" +
        this.state.filterbyinsuranceId +
        "&sort=" +
        (this.state.filter && this.state.filter != ""
          ? this.state.filter
          : "createdDate") +
        "," +
        (this.state.sorting && this.state.sorting != ""
          ? this.state.sorting
          : "desc") +
        "";
    }
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: generalAuthTokenHeader(),
    })
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: "Facility",
            message: "Bad response from server",
            type: "danger",
          };
        } else if (response.ok) {
          let totalPatientCount = response.headers.get("X-Total-Count");
          this.setState({
            total: totalPatientCount,
          });
        } else if (response.status === 401) {
          userService.logout();
          //window.location.reload(true);
          window.location.replace(baseRoutes.login.path);
        } else {
          showNotification = {
            title: "Facility",
            message: "Bad response from server",
            type: "danger",
          };
        }
        return response.json();
      })
      .then((data) => {
        let localPatientList = this.state.patientList;
        if (this.state.currentPage == 0) {
          localPatientList = data;
        } else {
          localPatientList = localPatientList.concat(data);
        }
        let totalPatientShowing = localPatientList.length;
        let loadMore = false;
        let loadingState = false;
        let currentPage = this.state.currentPage;
        if (this.state.total > totalPatientShowing && !isDeclineOrAccept) {
          loadMore = true;
          loadingState = true;
          currentPage = currentPage + 1;
        }
        localStorage.setItem(PATIENT_LIST, JSON.stringify(localPatientList));
        this.setState({
          patientList: localPatientList,
          totalPatientShowing: totalPatientShowing,
          loadMore: loadMore,
          currentPage: currentPage,
          loadingState: loadingState,
          loadingPatient: false,
        });
        userService.showNotification(showNotification);
      })
      .catch((error) => {
        showNotification = {
          title: "Facility",
          message: "Bad response from server",
          type: "danger",
        };
        this.setState({
          loadingPatient: false,
        });
        userService.showNotification(showNotification);
        return response;
      });
    this.props.reduxLoad(true);
  }
  handleChangeInput = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.validateDeclineReferralFormField(name, value);
      }
    );
  };
  handleSubmit(event) {
    event.preventDefault();
    if (this.state.selectedPatientId && this.state.selectedPatientData) {
      this.updatePatient(false);
    } else {
      this.createPatient();
    }
  }
  validateDeclineReferralFormField = (fieldName, fieldValue) => {
    let fieldValidationErrors = this.state.formErrors;
    let declineReasonValid = this.state.declineReasonValid;

    switch (fieldName) {
      case "declineReason":
        let errMsg = "";
        if (fieldValue.trim().length > 0) {
          if (fieldValue.trim().length > 255) {
            declineReasonValid = false;
            errMsg = "Reason cannot be greater than 255 characters.";
          } else {
            declineReasonValid = true;
          }
        } else {
          declineReasonValid = false;
          errMsg = enMsg.reasonRequiredMsg;
        }
        fieldValidationErrors.declineReason = declineReasonValid ? "" : errMsg;
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        declineReasonValid: declineReasonValid,
      },
      this.validateDeclineReferralForm
    );
  };
  validateDeclineReferralForm() {
    return this.state.declineReasonValid;
  }
  search(event) {
    let searchKeyword = event.target.value.trim();
    this.setState({
      patientSearchKeyword: searchKeyword,
      currentPage: 0,
      selectedPatientId: "",
      patientName: "",
      patientCategoryList: [],
    });
    return true;
  }
  handlePatientSorting = (event) => {
    const name = event.target.name;
    let sortByValue = event.target.value;
    if (name == "filterbyinsuranceId") {
      sortByValue =
        event.target.value || event.target.value == "0"
          ? event.target.value
          : "";
    }

    this.setState(
      {
        [name]: sortByValue,
        currentPage: 0,
      },
      () => this.fetchPatients()
    );
  };

  loadMorePatient() {
    let localPatientList = this.state.patientList;
    this.setState(
      {
        loadMore: false,
      },
      () => this.fetchPatients()
    );
  }

  selectPatientCategory = (catagoryId) => {
    let patientCategories = this.state.patientCategories;
    if (patientCategories.includes(catagoryId)) {
      const index = patientCategories.indexOf(catagoryId);
      if (index > -1) {
        patientCategories.splice(index, 1);
      }
    } else {
      patientCategories.push(catagoryId);
    }
    this.setState(
      {
        patientCategories: patientCategories,
      },
      () => {
        this.validateField("patientCategoties", patientCategories);
      }
    );
  };
  getPercentageCategoryVales(percentageVal, valueArr) {
    var max_of_array = Math.max.apply(Math, valueArr);
    var percent = (max_of_array * percentageVal) / 100;
    let localArr = [];
    localArr = valueArr.map((arrVal, key) => {
      if (arrVal < 0) {
        arrVal = arrVal * percent;
      }
      return arrVal;
    });
    return localArr;
  }

  refferalActionModalOpen = () => {
    let fieldValidationErrors = this.state.formErrors;
    fieldValidationErrors.declineReason = "";
    this.setState({
      referralActionModalStatus: true,
      declineReason: "",
      formErrors: fieldValidationErrors,
    });
  };
  refferalActionModalClose = () => {
    this.setState({
      referralActionModalStatus: false,
    });
  };
  acceptedReferralModalOpen = () => {
    this.setState({
      acceptedReferralModalStatus: true,
    });
  };
  acceptedReferralModalClose = () => {
    this.setState({
      acceptedReferralModalStatus: false,
    });
  };
  updateReferralStatus = (id, value, comment) => {
    this.state.patientList.map((patient, index) => {
      if (patient.id == this.state.selectedPatientData.id) {
        this.setState({
          patientList: update(this.state.patientList, {
            [index]: {
              refferalProviderEntity: {
                0: {
                  masterRefferalStatusEntity: {
                    id: {
                      $set: id,
                    },
                    refferalStatusValue: {
                      $set: value,
                    },
                  },
                },
              },
            },
          }),
          selectedPatientData: update(this.state.selectedPatientData, {
            refferalProviderEntity: {
              0: {
                masterRefferalStatusEntity: {
                  id: {
                    $set: id,
                  },
                  refferalStatusValue: {
                    $set: value,
                  },
                },
                comment: {
                  $set: comment,
                }
                ,
              },
            },
          }),
        });
      }
    });
  };
  async declineFormSubmit(e) {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    let showNotification = {};
    let apiUrl =
      apiPath.providerDeclineReferral +
      "?refferalId=" +
      this.state.selectedPatientData.refferalId +
      "&comment=" +
      this.state.declineReason;
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: generalAuthTokenHeader(),
    })
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: "Referral",
            message: "Bad response from server",
            type: "danger",
          };
        } else if (response.ok) {
          this.updateReferralStatus(5, "Decline", this.state.declineReason);
          showNotification = {
            title: "Referral",
            message: "Referral has been declined.",
            type: "success",
          };
        } else {
          showNotification = {
            title: "Referral",
            message: "Bad response from server",
            type: "danger",
          };
        }
        return response.json();
      })
      .then((data) => {
        if (data.status == 400 && data.title) {
          showNotification = {
            title: "Referral",
            message: data.title,
            type: "danger",
          };
        }
      })
      .catch((error) => {});
    this.setState({
      loading: false,
    });
    this.refferalActionModalClose();
    userService.showNotification(showNotification);
  }
  getPatientHospitalName(patientHospitalId) {
    this.state.hospitalList.map((el) => {
      if (el.previousHospitalId == patientHospitalId) {
        return el.hospitalName;
      }
    });
  }
  selectPatientDetail = async (
    id,
    refferalId,
    renderKey,
    isReload,
    modalKey
  ) => {
    this.setState({
      loading: true,
    });
    if (isReload != "false") {
      this.setState({
        loading: true,
        selectedPatientData: "",
      });
    }
    let showNotification = {};
    let apiUrl = apiPath.getReferralDetail + "?refferalId=" + refferalId;
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: generalAuthTokenHeader(),
    })
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: "Referral",
            message: "Bad response from server",
            type: "danger",
          };
        } else if (response.ok) {
        } else {
          showNotification = {
            title: "Referral",
            message: "Bad response from server",
            type: "danger",
          };
        }
        return response.json();
      })
      .then((data) => {
        if (data.status == 400 && data.title) {
          showNotification = {
            title: "Referral",
            message: data.title,
            type: "danger",
          };
        }
        if (data.refferalId) {
          let patient = data;
          if (isReload == "false" && patient.refferalStatus != 1) {
            this.providerCommentModalOpen(modalKey);
          }
          let categoryValue = "";
          let hospitalValue = "";
          let insuranceName = "";
          let searchCriteria = [];
          let categoryId = "";
          let estimateDischargeDate = "";
          let actualDischargeDate = null;
          let patientList = [...this.state.patientList];
          let newPatientList = [];
          let selectedcomment ="";
          if (
            data.refferalProviderEntity[0].refferalProviderComment.length >= 2 &&
            data.refferalProviderEntity[0].refferalProviderComment[1].comment
          ) {
            selectedcomment = data.refferalProviderEntity[0].refferalProviderComment[1].comment;
          }
          if (patientList && patientList.length) {
            newPatientList = patientList.map((pList) => {
              if (pList.id == id) {
                pList = patient;
              }
              return pList;
            });
          }
          if (
            patient.refferalPatientEntity[0] &&
            patient.refferalPatientEntity[0].searchHistory
          ) {
            const url = new URLSearchParams(
              patient.refferalPatientEntity[0].searchHistory.search
            );
            let subCategoryId = url.get("subCategoryId");
            subCategoryId = subCategoryId.split(",");
            if (subCategoryId && subCategoryId[1]) {
              categoryId = subCategoryId[1]
                .split("-")
                .map((item) => Number(item));
            }
          }
          if (patient.refferalPatientEntity[0]) {
            this.state.insuranceList.map((el) => {
              if (
                el.insuranceId ==
                patient.refferalPatientEntity[0].patientEntity.insuranceId
              ) {
                insuranceName = el.insuranceName;
              }
            });
          }
          this.state.categoryList.map((el) => {
            if (el.id == patient.categoryId) {
              categoryValue = el.categoryValue;
            }
            if (categoryId && categoryId.includes(el.id)) {
              searchCriteria.push(el.categoryValue);
            }
          });
          if (patient.refferalPatientEntity[0]) {
            this.state.hospitalList.map((el) => {
              if (
                el.previousHospitalId ==
                patient.refferalPatientEntity[0].patientEntity
                  .previousHospitalId
              ) {
                hospitalValue = el.hospitalName;
              }
            });
          }
          estimateDischargeDate = this.dateUtility.format(
            new Date(
              patient.refferalPatientEntity[0].patientEntity.formattedEstimatedDischargeDate
            ),
            "MM/dd/yyyy"
          );
          actualDischargeDate =
            patient.refferalPatientEntity[0].patientEntity
              .actualDischargeDate &&
            this.dateUtility.format(
              new Date(
                patient.refferalPatientEntity[0].patientEntity.actualDischargeDate
              ),
              "MM/dd/yyyy"
            );

          this.setState({
            patientList: newPatientList,
            selectedPatientData: patient,
            searchCriteria: searchCriteria,
            selectedPatientCategoryValue: categoryValue,
            selectedPatientHospitalValue: hospitalValue,
            selectedPatientInsuranceValue: insuranceName,
            patientListSeletedIndex: renderKey,
            estimateDischargeDate: estimateDischargeDate,
            actualDischargeDate: actualDischargeDate,
            selectRererralId: refferalId,
            selectRererralItemId: id,
            refferalTransportEntity: data.refferalTransportEntity,
            refferalDmeEntity: data.refferalDmeEntity,
            selectedcomment:selectedcomment
          });
        }
        console.log("This is all data: "+data);       
        userService.showNotification(showNotification);
        return data;
      })
      .catch((error) => {});
    this.setState({
      loading: false,
    });
    return;
  };
  acceptReferral = async (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    let showNotification = {};
    let apiUrl =
      apiPath.providerAcceptReferral +
      "?refferalId=" +
      this.state.selectedPatientData.refferalId;
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: generalAuthTokenHeader(),
    })
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: "Referral",
            message: "Bad response from server",
            type: "danger",
          };
        } else if (response.ok) {
          this.updateReferralStatus(6, "Accepted", "");
          showNotification = {
            title: "Referral",
            message: "Referral has been accepted.",
            type: "success",
          };
        } else {
          showNotification = {
            title: "Referral",
            message: "Bad response from server",
            type: "danger",
          };
        }
        return response.json();
      })
      .then((data) => {
        if (data.status == 400 && data.title) {
          showNotification = {
            title: "Referral",
            message: data.title,
            type: "danger",
          };
        } else if (data.id) {
          let localPatients = [];
          let localPatientArr = this.state.patientList;
          if (localPatientArr && localPatientArr.length) {
            localPatients = localPatientArr.map((pList) => {
              if (pList.id == data.id) {
                if (
                  data.refferalProviderEntityNew &&
                  data.refferalProviderEntityNew.length
                ) {
                  data.refferalProviderEntity = data.refferalProviderEntityNew;
                }
                pList = data;
              }
              return pList;
              console.log(data);
            });         
          }
          this.setState({
            selectedPatientData: data,
            patientList: localPatients,
          });
        }
      })
      .catch((error) => {
        showNotification = {
          title: "Referral",
          message: "Bad response from server",
          type: "danger",
        };
      });
    this.setState({
      loading: false,
    });
    this.acceptedReferralModalClose();
    userService.showNotification(showNotification);
  };
  providerCommentModalOpen = (key) => {
    this.setState({
      selectedPatientKey: key,
      providerCommentModalStatus: true,
    });
  };
  providerCommentModalClose = () => {
    this.setState({
      providerCommentModalStatus: false,
    });
  };
  confirmModalOpen = (message) => {
    this.setState({
      confirmModalMessage: message,
      confirmModalStatus: true,
    });
  };
  confirmModalClose = () => {
    this.setState({
      confirmModalStatus: false,
    });
  };
  confirmCommentModalClose = () => {
    this.setState({
      confirmBoxStatus: false,
    });
  };
  confirmCommentModalOpen = () => {
    this.setState({
      confirmBoxStatus: true,
    });
  };
  removeRequestFromDash = async () => {
    this.setState({
      loading: true,
    });
    let showNotification = {};
    let apiUrl =
      apiPath.removeReferralFromDashboard +
      "?refferalId=" +
      this.state.selectedPatientData.refferalId;
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: generalAuthTokenHeader(),
    })
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: "Referral",
            message: "Bad response from server",
            type: "danger",
          };
        } else if (response.ok) {
          this.setState((prevState) => ({
            patientList: update(prevState.patientList, {
              $splice: [[this.state.patientListSeletedIndex, 1]],
            }),
            selectedPatientData: "",
            total: prevState.total - 1,
          }));
          showNotification = {
            title: "Referral",
            message: "Referral has been removed.",
            type: "success",
          };
        } else {
          showNotification = {
            title: "Referral",
            message: "Bad response from server",
            type: "danger",
          };
        }
      })
      .then((data) => {})
      .catch((error) => {
        showNotification = {
          title: "Referral",
          message: "Bad response from server",
          type: "danger",
        };
      });
    this.setState({
      loading: false,
    });
    this.confirmModalClose();
    userService.showNotification(showNotification);
    window.location.reload();
  };
  searchReturn(event) {
    if (event.target.keyCode === 13 || event.key === "Enter") {
      event.preventDefault();
      this.fetchPatients();
    }
  }
  handleChangeInputForComment = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState(
      {
        [name]: value,
      },
      () => {
        this.validateField(name, value);
      }
    );
  };
  validateField = (fieldName, fieldValue) => {
    let fieldValidationErrors = this.state.formErrors;
    let commentToDPValid = this.state.commentToDPValid;
    switch (fieldName) {
      case "commentToDP":
        let commentErr = "";
        if (fieldValue.trim().length == 0) {
          commentToDPValid = false;
          commentErr = "Comment is required";
        } else if (fieldValue.trim().length > MAX_INPUT_LENGTH_LONG) {
          commentToDPValid = false;
          commentErr = "Comment length cannot exceed 255 characters";
        } else {
          commentToDPValid = true;
          commentErr = "";
        }
        fieldValidationErrors.commentToDP = commentErr;
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        commentToDPValid: commentToDPValid,
      },
      this.validateForm
    );
  };
  validateForm() {
    return this.state.commentToDPValid;
  }
  

  sendComment = async (event) => {
    event.preventDefault();
    let data = {
      comment: this.state.commentToDP,
      providerId: this.state.selectedPatientData.refferalProviderEntity[0]
        .providerId,
      refferalId: this.state.selectedPatientData.refferalId,
    };
    this.setState({ loading: true });
    let showNotification = {};
    let modalCloseStatus = 1;
    const response = await fetch(apiPath.postPrelimComment, {
      method: "POST",
      headers: generalAuthTokenHeader(),
      body: JSON.stringify(data),
      data: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: "Comment",
            message: enMsg.badResponseFromServer,
            type: "danger",
          };
        } else if (response.ok) {
        } else {
          showNotification = {
            title: "Comment",
            message: enMsg.badResponseFromServer,
            type: "danger",
          };
        }
        return response.json();
      })
      .then((data) => {
        if (data.id) {
          this.setState({
            isPrelimCommentDone: true,
            prelimCommentByProvider: data.comment,
            commentPostReferralId: this.state.selectedPatientData.refferalId,
            commentToDP: "",
          });
          showNotification = {
            title: "Comment",
            message: "Message has been sent to the dishcharge planner",
            type: "success",
          };
        } else if (data.status == 400 && data.title) {
          showNotification = {
            title: "Comment",
            message: data.title,
            type: "danger",
          };
        }
      })
      .catch((error) => {
        showNotification = {
          title: "Comment",
          message: enMsg.clientSideError,
          type: "danger",
        };
      });
    userService.showNotification(showNotification);
    this.setState({ loading: false });
    this.setState({
      confirmBoxStatus: false,
    });
  };
  handleClose() {}
  commentBoxModalSubmit = (event) => {
    event.preventDefault();
    this.setState({
      confirmBoxStatus: true,
    });
  };
  render() {
    const {
      currentPage,
      total,
      sorting,
      filter,
      patientCategoryList,
      loading,
      patientName,
      selectedPatientId,
      patientList,
      hospital,
      insurance,
      gender,
      insuranceList,
      categoryList,
      hospitalList,
      patientCategories,
      selectionCount,
      selectedPatientData,
      totalPatientShowing,
      filterby,
      filterbyinsuranceId,
      
      
    } = this.state;
    const { classes } = this.props;
    // const menu = this.menuItems;
    return (
      <div id="facility-index">
        <ConfirmModal
          title="Alert"
          message={this.state.confirmModalMessage}
          confirmModalStatus={this.state.confirmModalStatus}
          confirmModalClose={this.confirmModalClose}
          submit={this.removeRequestFromDash}
          loading={this.state.loading}
        />
        <ChangePassword
          id="change-pssword-dialog"
          keepMounted
          open={this.state.openModal}
          handleChangePasswordDialog={this.handleClose}
          onClose={this.handleClose}
          value={""}
          allowCloseModal={false}
        />
        <BAAComponent
          open={this.state.bbaCondition}
          handleBAAAgree={this.handleBAAAgree}
        />
        <TermsComponent
          open={this.state.termNcondition}
          handleTNCAgree={this.handleTNCAgree}
        />
        {selectedPatientData && (
          <ProviderCommentModal
            status={this.state.providerCommentModalStatus}
            close={this.providerCommentModalClose}
            selectedPatientData={selectedPatientData}
            selectedPatientInsuranceValue={
              this.state.selectedPatientInsuranceValue
            }
            insuranceList={this.state.insuranceList}
            searchCriteria={this.state.searchCriteria}
            categoryList={this.state.categoryList}
            estimateDischargeDate={this.state.estimateDischargeDate}
            actualDischargeDate={this.state.actualDischargeDate}
            commentsThread={this.state.commentsThread}
            selectedPatientKey={this.state.selectedPatientKey}
          />
        )}
        <Dialog
          open={this.state.referralActionModalStatus}
          maxWidth={"sm"}
          onClose={this.refferalActionModalClose}
          TransitionComponent={Transition}
          aria-labelledby="decline-referral-title"
          className="add-modal front-modal ref-decline-modal"
        >
          <DialogTitle
            className="add-modal-title decline-text"
            id="decline-referral-title"
          >
            This will decline the Referral: {selectedPatientData.refferalId}{" "}
            {selectedPatientData.dischargeplannerEntity &&
              `from ` +
                selectedPatientData.dischargeplannerEntity.facility
                  .facilityName}
            <IconButton
              className="closeButton"
              aria-label="close"
              onClick={this.refferalActionModalClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <form onSubmit={this.declineFormSubmit}>
            <DialogContent>
              <Box display="flex" justifyContent="flex-start" mb={2}>
                <Box pr={1} width={1}>
                  <TextField
                    name="declineReason"
                    value={this.state.declineReason}
                    onChange={this.handleChangeInput}
                    data-validators="isRequired"
                    label="Decline Reason"
                    multiline={true}
                    rows={4}
                    InputLabelProps={{ className: "required-label" }}
                  />
                  <FormErrors
                    show={!this.state.declineReasonValid}
                    formErrors={this.state.formErrors}
                    fieldName="declineReason"
                  />
                </Box>
              </Box>
            </DialogContent>
            <DialogActions className="modal-actions" justify="center">
              <Button
                type="submit"
                className="btn5 dec"
                disabled={!this.validateDeclineReferralForm()}
              >
                {this.state.loading && (
                  <CircularProgress size={24} className="buttonProgress" />
                )}
                Decline Referral
              </Button>
              <Button onClick={this.refferalActionModalClose} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        <Dialog
          open={this.state.acceptedReferralModalStatus}
          maxWidth={"sm"}
          onClose={this.acceptedReferralModalClose}
          TransitionComponent={Transition}
          aria-labelledby="decline-referral-title"
          className="add-modal ref-accept-modal"
        >
          <DialogTitle className="add-modal-title" id="decline-referral-title">
            {selectedPatientData.refferalType == 1 ? (
              <div>
                Acknowledging Referral ID: {selectedPatientData.refferalId}{" "}
                {selectedPatientData.dischargeplannerEntity &&
                  `for ` +
                    selectedPatientData.dischargeplannerEntity.facility
                      .facilityName}
              </div>
            ) : (
              <div>
                Accepting Referral ID: {selectedPatientData.refferalId}{" "}
                {selectedPatientData.dischargeplannerEntity &&
                  `for ` +
                    selectedPatientData.dischargeplannerEntity.facility
                      .facilityName}
              </div>
            )}
            <IconButton
              className="closeButton"
              aria-label="close"
              onClick={this.acceptedReferralModalClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <form onSubmit={this.acceptReferral} noValidate>
            <DialogContent>
              <Box display="flex" justifyContent="flex-start">
                <Box pr={1} width={1}>
                  <p>
                    You are{" "}
                    {selectedPatientData.refferalType == 1
                      ? "acknowledging"
                      : "accepting"}{" "}
                    the referral under the{" "}
                    {this.state.selectedPatientCategoryValue} category for{" "}
                    {selectedPatientData &&
                      selectedPatientData.refferalPatientEntity.length}{" "}
                    patient(s).
                  </p>
                  <br></br>
                  <p>
                    Do you want to{" "}
                    {selectedPatientData.refferalType == 1
                      ? "acknowledge"
                      : "accept"}{" "}
                    this Referral Request?
                  </p>
                </Box>
              </Box>
            </DialogContent>
            <DialogActions className="modal-actions" justify="center">
              <Button type="submit" className="btn3">
                {this.state.loading && (
                  <CircularProgress size={24} className="buttonProgress" />
                )}
                {selectedPatientData.refferalType == 1
                  ? "Acknowledge"
                  : "Accept Referral"}
              </Button>
              <Button onClick={this.acceptedReferralModalClose} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        <Dialog
          open={this.state.confirmBoxStatus}
          maxWidth={"xs"}
          onClose={this.confirmCommentModalOpen}
          TransitionComponent={Transition}
          aria-labelledby="form-dialog-title"
          className="add-modal front-modal "
        >
          <DialogTitle className="add-modal-title" id="form-dialog-title">
            Comment
            <IconButton
              className="closeButton"
              aria-label="close"
              onClick={this.handleCloseCommentStatus}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box
              width={1}
              display="flex"
              justifyContent="flex-start"
              mb={1}
              mt={1}
            >
              <Box pr={2}>Do you want submit ?</Box>
            </Box>
          </DialogContent>
          <DialogActions className="modal-actions" justify="center">
            <Button type="button" className="btn1" onClick={this.sendComment}>
              Confirm
            </Button>
            <Button
              onClick={this.confirmCommentModalClose}
              className="cancel-link"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {this.state.loadingPatient || !this.state.reduxLoadFlag ? (
          <div className="LinearProgress-ref">
            <LinearProgress />
          </div>
        ) : (
          <div className="LinearProgress-ref"></div>
        )}
        {!this.state.openModal &&
        !this.state.bbaCondition &&
        !this.state.termNcondition ? (
          <Box
            display="flex"
            flexDirection="row"
            width={1}
            className="patient-list-page AllReferrals-page"
          >
            <Box pr={1.5} className="left-content-box-ref part-1">
              <div className="left-content-header">
                <Box display="flex" className="divcontainer-Ref" mb={2}>
                  <Box pr={1} className="title-box pl-0">
                    <h2 className="titlename">All Referrals</h2>
                  </Box>

                  <Box
                    className="action-box-ref flex-wrap"
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end"
                  >
                    <Box
                      className="action-box-ref"
                      display="flex"
                      flexDirection="row"
                      justifyContent="flex-end"
                    >
                      <Box pr={1}>
                        <div className="show-text">
                          {totalPatientShowing}/{total} Referrals{" "}
                        </div>
                      </Box>
                      <Box pr={1} className="box-filter-ref fr-1">
                        <Tooltip
                          title="Search By Referral ID, Facility Name, Patient Name"
                          placement="top"
                          arrow
                        >
                          <Paper component="form" className="search-box-cover">
                            <InputBase
                              className="search-box"
                              placeholder="Search Referral..."
                              onChange={this.search}
                              onKeyPress={this.searchReturn}
                            />
                            <IconButton
                              className=""
                              aria-label="search"
                              onClick={this.fetchPatients}
                            >
                              <SearchIcon />
                            </IconButton>
                          </Paper>
                        </Tooltip>
                      </Box>
                      <Box pr={1} className="box-filter-ref fr-1">
                        <FormGroup>
                          <FormControl variant="outlined">
                            <InputLabel>Filter By</InputLabel>
                            <Select
                              id="filterby"
                              color="secondary"
                              name="filterby"
                              className="filter-select"
                              value={filterby}
                              onChange={this.handlePatientSorting}
                            >
                              <MenuItem value="">Select</MenuItem>
                              {this.state.filterList.length ? (
                                this.state.filterList.map((facility) => (
                                  <MenuItem value={facility.key}>
                                    {facility.value}
                                  </MenuItem>
                                ))
                              ) : (
                                <MenuItem value=" ">Not Available</MenuItem>
                              )}
                            </Select>
                          </FormControl>
                        </FormGroup>
                      </Box>

                      <Box pr={1} className="box-filter-ref fr-1">
                        <FormGroup>
                          <FormControl variant="outlined">
                            <InputLabel>Sort By</InputLabel>
                            <Select
                              id="filter"
                              color="secondary"
                              name="filter"
                              className="filter-select"
                              value={filter}
                              onChange={this.handlePatientSorting}
                            >
                              <MenuItem value="createdDateTime">
                                Created Date
                              </MenuItem>
                              <MenuItem value="refferalId">
                                Referral ID
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </FormGroup>
                      </Box>
                      <Box className="box-filter-ref fr-1">
                        <FormGroup>
                          <FormControl
                            variant="outlined"
                            className={"mat_select"}
                          >
                            <InputLabel htmlFor="filter">Order By</InputLabel>
                            <Select
                              id="sorting"
                              color="secondary"
                              name="sorting"
                              className="filter-select"
                              value={sorting}
                              onChange={this.handlePatientSorting}
                            >
                              <MenuItem value="asc">ASC</MenuItem>
                              <MenuItem value="desc">DESC</MenuItem>
                            </Select>
                          </FormControl>
                        </FormGroup>
                      </Box>
                    </Box>
                    <Box
                      className=" end-box"
                      display="flex"
                      flexDirection="row"
                      justifyContent="flex-end"
                    >
                      <Box
                        pr={1}
                        className="box-filter-ref fr-1 insurance-filter"
                      >
                        <FormGroup>
                          <FormControl variant="outlined">
                            <InputLabel>Insurance</InputLabel>
                            <Select
                              id="filterbyinsuranceId"
                              color="secondary"
                              name="filterbyinsuranceId"
                              className="filter-select"
                              value={filterbyinsuranceId}
                              onChange={this.handlePatientSorting}
                            >
                              <MenuItem value="">Select</MenuItem>
                              
                              {this.state.insuranceList.length ? (
                                this.state.insuranceList.map((insurance) => (
                                 
                                  
                                  <MenuItem value={insurance.insuranceId}>
                                    {insurance.insuranceName}
                                  </MenuItem>
                                ))
                              ) : (
                                <MenuItem value=" ">Not Available</MenuItem>
                              )}
                            </Select>
                          </FormControl>
                        </FormGroup>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </div>

              <Box width={1} className="left-content-list">
                <div className="PatientList">
                  <Scrollbars
                    className="ref-scrollbox-1"
                    universal={true}
                    autoHide={false}
                  >
                    {patientList &&
                    typeof patientList === "object" &&
                    patientList.length ? (
                      <InfiniteScroll
                        pageStart={0}
                        loadMore={this.loadMorePatient}
                        threshold={50}
                        hasMore={this.state.loadMore}
                        loader={
                          <div className="loader" key={0}>
                            Loading ...
                          </div>
                        }
                        useWindow={false}
                        getScrollParent={() => this.scrollParentRef}
                      >
                        {patientList.length &&
                          patientList.map((referralPatient, key) => (
                            <Box
                              width={1}
                              display="flex"
                              flexDirection="row"
                              mb={2}
                              pr={2}
                              key={key}
                              className="id_box"
                            >
                              <div
                                className={`pointer list-item-content-Ref ${selectedPatientData.id ==
                                  referralPatient.id && `selected-Ref`}`}
                                onClick={() =>
                                  this.selectPatientDetail(
                                    referralPatient.id,
                                    referralPatient.refferalId,
                                    key
                                  )
                                }
                              >
                                <Box
                                  className="AllRef-content"
                                  display="flex"
                                  flexDirection="row"
                                  width={1}
                                >
                                  <Box pr={1} width={1 / 4} className="pointer">
                                    <div className="user-info-Ref">
                                      <div className="name id-name">
                                        ID: {referralPatient.refferalId}
                                        {referralPatient.notificationCount && 
                                        referralPatient.notificationCount !==
                                          0 ? (
                                          <span>
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="8"
                                              height="8"
                                              viewBox="0 0 8 8"
                                            >
                                              <circle
                                                id="Ellipse_3"
                                                data-name="Ellipse 3"
                                                cx="4"
                                                cy="4"
                                                r="4"
                                                fill="red"
                                              />
                                            </svg>
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div className="date-insurance">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="8.75"
                                          height="10"
                                          viewBox="0 0 8.75 10"
                                        >
                                          <path
                                            id="calendar-alt"
                                            d="M8.516,3.125H.234A.235.235,0,0,1,0,2.891v-.7A.938.938,0,0,1,.938,1.25h.938V.234A.235.235,0,0,1,2.109,0h.781a.235.235,0,0,1,.234.234V1.25h2.5V.234A.235.235,0,0,1,5.859,0h.781a.235.235,0,0,1,.234.234V1.25h.938a.938.938,0,0,1,.938.938v.7A.235.235,0,0,1,8.516,3.125ZM.234,3.75H8.516a.235.235,0,0,1,.234.234V9.063A.938.938,0,0,1,7.813,10H.938A.938.938,0,0,1,0,9.063V3.984A.235.235,0,0,1,.234,3.75ZM2.5,7.734A.235.235,0,0,0,2.266,7.5H1.484a.235.235,0,0,0-.234.234v.781a.235.235,0,0,0,.234.234h.781A.235.235,0,0,0,2.5,8.516Zm0-2.5A.235.235,0,0,0,2.266,5H1.484a.235.235,0,0,0-.234.234v.781a.235.235,0,0,0,.234.234h.781A.235.235,0,0,0,2.5,6.016ZM5,7.734A.235.235,0,0,0,4.766,7.5H3.984a.235.235,0,0,0-.234.234v.781a.235.235,0,0,0,.234.234h.781A.235.235,0,0,0,5,8.516Zm0-2.5A.235.235,0,0,0,4.766,5H3.984a.235.235,0,0,0-.234.234v.781a.235.235,0,0,0,.234.234h.781A.235.235,0,0,0,5,6.016Zm2.5,2.5A.235.235,0,0,0,7.266,7.5H6.484a.235.235,0,0,0-.234.234v.781a.235.235,0,0,0,.234.234h.781A.235.235,0,0,0,7.5,8.516Zm0-2.5A.235.235,0,0,0,7.266,5H6.484a.235.235,0,0,0-.234.234v.781a.235.235,0,0,0,.234.234h.781A.235.235,0,0,0,7.5,6.016Z"
                                            fill="#666"
                                          />
                                        </svg>
                                        Received:{" "}
                                        {this.dateUtility.format(
                                          new Date(
                                            referralPatient.refferalSendTime
                                          ),
                                          "MM/dd/yyyy"
                                        )}
                                      </div>
                                    </div>
                                  </Box>

                                  <Box
                                    pr={1}
                                    width={1 / 4}
                                    className="Ref-Hospital-Patients"
                                  >
                                    <div className="hospital">
                                      {referralPatient.dischargeplannerEntity &&
                                        referralPatient.dischargeplannerEntity
                                          .facility.facilityName}
                                    </div>
                                    
                                    
                                    {referralPatient.refferalPatientEntity
                                      .length > 1 && (
                                      <div className="patients-num">
                                        {
                                          referralPatient.refferalPatientEntity
                                            .length
                                        }{" "}
                                        Patients
                                      </div>
                                      
                                    )}
                                   <div className="hospital">
                                      {referralPatient.refferalPatientEntity &&
                                      referralPatient.refferalPatientEntity.length > 0 &&
                                      referralPatient.refferalPatientEntity[0].patientEntity &&
                                      referralPatient.refferalPatientEntity[0].patientEntity.masterInsurance &&
                                      referralPatient.refferalPatientEntity[0].patientEntity.masterInsurance.insuranceName
                                        ? referralPatient.refferalPatientEntity[0].patientEntity.masterInsurance.insuranceName
                                        : "No Insurance"}
                                    </div>
                                  </Box>

                                  <Box
                                    pr={1}
                                    width={1 / 4}
                                    className="Ref-category"
                                  >
                                    <div className="Ref-categorybox">
                                      <div className="category-value">
                                        {referralPatient.categoryId &&
                                          this.state.categoryList.map((el) => {
                                            if (
                                              el.id ==
                                              referralPatient.categoryId
                                            ) {
                                              return el.categoryValue;
                                            }
                                          })}
                                      </div>
                                    </div>
                                  </Box>
                                  <Box pr={1} className="Ref-tags">
                                    <div
                                      className={`tag ${
                                        referralPatient
                                          .refferalProviderEntity[0]
                                          .masterRefferalStatusEntity
                                          .refferalStatusValue &&
                                        referralPatient
                                          .refferalProviderEntity[0]
                                          .masterRefferalStatusEntity
                                          .refferalStatusValue
                                          ? referralPatient
                                              .refferalProviderEntity[0]
                                              .masterRefferalStatusEntity
                                              .refferalStatusValue
                                          : "noStatus"
                                      }`}
                                    >
                                      {
                                        referralPatient
                                          .refferalProviderEntity[0]
                                          .masterRefferalStatusEntity
                                          .refferalStatusValue
                                      }
                                    </div>
                                    <div className="tag-date">
                                      {referralPatient
                                        .refferalProviderEntity[0] &&
                                        this.dateUtility.format(
                                          new Date(
                                            referralPatient.refferalProviderEntity[0].formattedUpdatedDateTime
                                          ),
                                          "MM/dd/yyyy"
                                        )}
                                    </div>
                                  </Box>
                                </Box>
                              </div>
                            </Box>
                          ))}
                      </InfiniteScroll>
                    ) : this.state.loadingPatient ? (
                      <div className="no-data-div">
                        <div>Loading...</div>
                      </div>
                    ) : (
                      <div className="no-data-div">
                        {" "}
                        <div className="referal_avail">
                          No referral available{" "}
                        </div>
                      </div>
                    )}
                  </Scrollbars>
                </div>
              </Box>
            </Box>
            <Box
              pr={1.5}
              className="left-content-box-ref part-2 patient_bottom"
            >
              <div className="referral-right-box">
                {selectedPatientData ? (
                  <div className="referral-right-content">
                    <Box
                      display="flex"
                      flexDirection="row"
                      width={1}
                      mb={2}
                      className="referral-right-header"
                    >
                      <div className="id-tag">
                        <h3 className="ref-id">
                          Referral ID : {selectedPatientData.refferalId}
                        </h3>
                        <div className="current-status tag">
                          {
                            selectedPatientData.refferalProviderEntity[0]
                              .masterRefferalStatusEntity.refferalStatusValue
                          }
                        </div>
                        <div className="tag-date-right">
                          {selectedPatientData.refferalProviderEntity[0]
                            .masterRefferalStatusEntity.id !== 4 &&
                            selectedPatientData.refferalProviderEntity[0]
                              .formattedUpdatedDateTime}
                        </div>
                        <div className="facname">
                          Facility:{" "}
                          {selectedPatientData.dischargeplannerEntity &&
                          selectedPatientData.dischargeplannerEntity.facility
                            .facilityName
                            ? selectedPatientData.dischargeplannerEntity
                                .facility.facilityName
                            : ""}
                        </div>
                      </div>
                      <div className="id-date">
                        <div className="extended-info">Referral extended</div>
                        <div className="extended-date">
                          {this.dateUtility.format(
                            new Date(selectedPatientData.refferalSendTime),
                            "MM/dd/yyyy"
                          )}
                        </div>
                      </div>
                    </Box>
                    <div className="id-tag">
                      <h3 className="ref-id">
                        Patient Referred -{" "}
                        <span className="patient_ref_text">
                          {REFERRAL_TYPES[selectedPatientData.refferalType]}
                        </span>
                      </h3>
                    </div>

                    <div class="bottom_dashbox">
                      <Scrollbars
                        className="ref-scrollbox new_div"
                        style={{ height: "calc(100vh - 410px)" }}
                        universal={true}
                        autoHide={false}
                      >
                        {selectedPatientData.refferalPatientEntity[0] ? (
                          <Box
                            width={1}
                            display="flex"
                            flexDirection="column"
                            mb={2}
                            pr={1.5}
                            className="id_box"
                          >
                            {selectedPatientData.refferalPatientEntity.map(
                              (patient, key) => (
                                <div
                                  key={key}
                                  className={`list-item-content-Ref no-shadow`}
                                >
                                  <Box
                                    className="AllRef-content"
                                    display="flex"
                                    flexDirection="row"
                                    width={1}
                                    key={key}
                                  >
                                    <Box pr={1}>
                                      <div className="patient-info-Ref">
                                        <div className="name">
                                          {patient.patientEntity.lastName &&
                                            patient.patientEntity.lastName +
                                              ", "}
                                          {patient.patientEntity.firstName}
                                        </div>

                                        {(selectedPatientData
                                          .refferalProviderEntity[0]
                                          .masterRefferalStatusEntity.id == 3 ||
                                          selectedPatientData
                                            .refferalProviderEntity[0]
                                            .masterRefferalStatusEntity.id ==
                                            6) && (
                                          // <div onClick={() => this.providerCommentModalOpen(key)} className="commentIcon pointer">
                                          <div
                                            onClick={() => {
                                              this.selectPatientDetail(
                                                this.state.selectRererralItemId,
                                                this.state.selectRererralId,
                                                this.state
                                                  .patientListSeletedIndex,
                                                "false",
                                                key
                                              );
                                            }}
                                            className="commentIcon pointer"
                                          >
                                            <Tooltip
                                              title="See patient info"
                                              placement="top"
                                              arrow
                                            >
                                              <img src={commentIcon} />
                                            </Tooltip>
                                          </div>
                                        )}
                                        <div className="info-text">
                                          {patient.patientEntity
                                            .actualDischargeDate
                                            ? "Discharged: "
                                            : "Est Discharge:"}
                                          {patient.patientEntity
                                            .actualDischargeDate
                                            ? this.dateUtility.format(
                                                new Date(
                                                  patient.patientEntity.actualDischargeDate
                                                ),
                                                "MM/dd/yyyy"
                                              )
                                            : patient.patientEntity
                                                .formattedEstimatedDischargeDate
                                            ? this.dateUtility.format(
                                                new Date(
                                                  patient.patientEntity.formattedEstimatedDischargeDate
                                                ),
                                                "MM/dd/yyyy"
                                              )
                                            : "N/A"}
                                        </div>
                                        {(selectedPatientData
                                          .refferalProviderEntity[0]
                                          .masterRefferalStatusEntity.id == 3 ||
                                          selectedPatientData
                                            .refferalProviderEntity[0]
                                            .masterRefferalStatusEntity.id ==
                                            6 ||
                                          selectedPatientData
                                            .refferalProviderEntity[0]
                                            .masterRefferalStatusEntity.id ==
                                            4) && (
                                          <div
                                            className="pink_button"
                                            onClick={() => {
                                              this.selectPatientDetail(
                                                this.state.selectRererralItemId,
                                                this.state.selectRererralId,
                                                this.state
                                                  .patientListSeletedIndex,
                                                "false",
                                                key
                                              );
                                            }}
                                          >
                                            <button className="chat_btn1 btn1">
                                              {" "}
                                              Detail{" "}
                                              {this.state.loading && (
                                                <CircularProgress
                                                  size={24}
                                                  className="buttonProgress"
                                                />
                                              )}
                                            </button>
                                            {/* <button className="chat_btn2 btn1"> Download</button> */}
                                          </div>
                                        )}
                                      </div>
                                    </Box>

                                    <Box
                                      pr={1}
                                      className="Ref-Hospital-Patients"
                                    >
                                      <div className="info-text">
                                        Insurance:{" "}
                                        {this.state.insuranceList.map(
                                          (insuranceList) =>
                                            insuranceList.insuranceId ==
                                              patient.patientEntity
                                                .insuranceId &&
                                            insuranceList.insuranceName
                                        )}
                                      </div>
                                      {patient.patientEntity.patientStatusId ==
                                        3 && (
                                        <div className="info-text">
                                          Discharged-
                                          {this.dateUtility.format(
                                            new Date(
                                              patient.patientEntity.formattedEstimatedDischargeDate
                                            ),
                                            "MM/dd/yyyy"
                                          )}
                                        </div>
                                      )}
                                    </Box>

                                    <Box
                                      display="flex"
                                      flexDirection="row"
                                      justifyContent="center"
                                      className="referral-right-tags"
                                    >
                                      {this.state.searchCriteria &&
                                        this.state.searchCriteria.map(
                                          (criteria, key) => (
                                            <div
                                              key={key}
                                              className={`tag ref-tag mr-10`}
                                            >
                                              {criteria}
                                            </div>
                                          )
                                        )}
                                    </Box>
                                  </Box>
                                </div>
                              )
                            )}
                            {/* Transport work */}
                            {this.state.refferalTransportEntity && (
                              <div>
                                <Box className="Decline-Ref-Form">
                                  <div className="comment-info-box">
                                    <div className="comment-title">
                                      Comments to Potential Providers{" "}
                                    </div>
                                    <Box
                                      width={1}
                                      display="flex"
                                      flexDirection="row"
                                      mb={2}
                                      className="comment-info"
                                    >
                                      <div className="comment-info-user">
                                        Pickup Date: 
                                      </div>
                                      <div className="comment-info-text">
                                        {moment
                                          .utc(
                                            this.state.refferalTransportEntity
                                              .transportDate
                                          )
                                          // .add(offset, "minutes")
                                          .format("MM/DD/yyyy")}
                                      </div>
                                    </Box>
                                    <Box
                                      width={1}
                                      display="flex"
                                      flexDirection="row"
                                      mb={2}
                                      className="comment-info"
                                    >
                                      <div className="comment-info-user">
                                        Pickup Time: 
                                      </div>
                                      <div className="comment-info-text">
                                        {
                                          this.state.refferalTransportEntity
                                            .transportPickupTime
                                        }
                                      </div>
                                    </Box>
                                    {this.state.refferalTransportEntity
                                      .transportPickUpAddress ? (
                                      <Box
                                        width={1}
                                        display="flex"
                                        flexDirection="row"
                                        mb={2}
                                        className="comment-info"
                                      >
                                        <div className="comment-info-user">
                                          Pickup Address: 
                                        </div>
                                        <div className="comment-info-text">
                                          {
                                            this.state.refferalTransportEntity
                                              .transportPickUpAddress
                                          }
                                        </div>
                                      </Box>
                                    ) : null}

                                    {this.state.refferalTransportEntity.room ? (
                                      <Box
                                        width={1}
                                        display="flex"
                                        flexDirection="row"
                                        mb={2}
                                        className="comment-info"
                                      >
                                        <div className="comment-info-user">
                                          Room: 
                                        </div>
                                        <div className="comment-info-text">
                                          {
                                            this.state.refferalTransportEntity
                                              .room
                                          }
                                        </div>
                                      </Box>
                                    ) : null}

                                    {this.state.refferalTransportEntity
                                      .steFloor ? (
                                      <Box
                                        width={1}
                                        display="flex"
                                        flexDirection="row"
                                        mb={2}
                                        className="comment-info"
                                      >
                                        <div className="comment-info-user">
                                          Floor: 
                                        </div>
                                        <div className="comment-info-text">
                                          {
                                            this.state.refferalTransportEntity
                                              .steFloor
                                          }
                                        </div>
                                      </Box>
                                    ) : null}

                                    <Box
                                      width={1}
                                      display="flex"
                                      flexDirection="row"
                                      mb={2}
                                      className="comment-info"
                                    >
                                      <div className="comment-info-user">
                                        Drop off Address: 
                                      </div>
                                      <a
                                        href={`http://maps.google.com/maps?q=${this.state.refferalTransportEntity.transportDropLatitude},${this.state.refferalTransportEntity.transportDropLongitude}`}
                                        // eslint-disable-next-line react/jsx-no-target-blank
                                        target="_blank"
                                        className="comment-info-text"
                                      >
                                        {getFormattedAddress(
                                          this.state.refferalTransportEntity
                                            .transportDropMapObject
                                        )}
                                      </a>
                                    </Box>
                                    <Box
                                      width={1}
                                      display="flex"
                                      flexDirection="row"
                                      mb={2}
                                      className="comment-info"
                                    >
                                      <div className="comment-info-user">
                                        Needs: 
                                      </div>
                                      <div className="comment-info-text">
                                        {
                                          this.state.refferalTransportEntity
                                            .transportNeeds
                                        }
                                      </div>
                                    </Box>
                                    <Box
                                      width={1}
                                      display="flex"
                                      flexDirection="row"
                                      mb={2}
                                      className="comment-info"
                                    >
                                      <div className="comment-info-user">
                                        Wait Time: 
                                      </div>
                                      <div className="comment-info-text">
                                        {
                                          this.state.refferalTransportEntity
                                            .waitTime
                                        }
                                      </div>
                                    </Box>

                                    <Box
                                      width={1}
                                      display="flex"
                                      flexDirection="row"
                                      mb={2}
                                      className="comment-info"
                                    >
                                      <div className="comment-info-user">
                                        Companinon Needed
                                      </div>
                                      <div className="comment-info-text">
                                        {this.state.refferalTransportEntity
                                          .CompanionNeeded
                                          ? "Yes"
                                          : "No"}
                                      </div>
                                    </Box>

                                    {this.state.refferalTransportEntity
                                      .physicianToSee ? (
                                      <Box
                                        width={1}
                                        display="flex"
                                        flexDirection="row"
                                        mb={2}
                                        className="comment-info"
                                      >
                                        <div className="comment-info-user">
                                          Physician to see: 
                                        </div>
                                        <div className="comment-info-text">
                                          {
                                            this.state.refferalTransportEntity
                                              .physicianToSee
                                          }
                                        </div>
                                      </Box>
                                    ) : null}
                                    {this.state.refferalTransportEntity
                                      .reasonForAppointment ? (
                                      <Box
                                        width={1}
                                        display="flex"
                                        flexDirection="row"
                                        mb={2}
                                        className="comment-info"
                                      >
                                        <div className="comment-info-user">
                                          Reason for appointment: 
                                        </div>
                                        <div className="comment-info-text">
                                          {
                                            this.state.refferalTransportEntity
                                              .reasonForAppointment
                                          }
                                        </div>
                                      </Box>
                                    ) : null}
                                    {this.state.refferalTransportEntity
                                      .payor ? (
                                      <Box
                                        width={1}
                                        display="flex"
                                        flexDirection="row"
                                        mb={2}
                                        className="comment-info"
                                      >
                                        <div className="comment-info-user">
                                          Payor: 
                                        </div>
                                        <div className="comment-info-text">
                                          {
                                            this.state.refferalTransportEntity
                                              .payor
                                          }
                                        </div>
                                      </Box>
                                    ) : null}

                                    {this.state.refferalTransportEntity
                                      .comment ? (
                                      <Box
                                        width={1}
                                        display="flex"
                                        flexDirection="row"
                                        mb={2}
                                        className="comment-info"
                                      >
                                        <div className="comment-info-user">
                                          Appointment details: 
                                        </div>
                                        <div className="comment-info-text">
                                          {
                                            this.state.refferalTransportEntity
                                              .comment
                                          }
                                        </div>
                                      </Box>
                                    ) : null}
                                  </div>
                                </Box>

                                {/* {(selectedPatientData.refferalProviderEntity[0]
                                .refferalProviderComment.length == 2 ||
                                (this.state.prelimCommentByProvider != "" &&
                                  selectedPatientData.refferalId ==
                                    this.state.commentPostReferralId)) && (
                                <div className="comment-info-box">
                                  <div className="comment-title">
                                    Comments to Potential Providers
                                  </div>

                                  <Box
                                    width={1}
                                    display="flex"
                                    flexDirection="row"
                                    mb={2}
                                    className="comment-info"
                                  >
                                    <div className="comment-info-user">
                                      Discharge User{" "}
                                    </div>
                                    <div className="comment-info-text">
                                      {selectedPatientData
                                        .refferalProviderEntity[0]
                                        .refferalProviderComment[0].sender ===
                                      "Discharge Planner"
                                        ? selectedPatientData
                                            .refferalProviderEntity[0]
                                            .refferalProviderComment[0].comment
                                        : selectedPatientData
                                            .refferalProviderEntity[0]
                                            .refferalProviderComment[1].comment}
                                    </div>
                                  </Box>
                                  <Box
                                    width={1}
                                    display="flex"
                                    flexDirection="row"
                                    mb={2}
                                    className="comment-info"
                                  >
                                    <div className="comment-info-user">
                                      Provider Response
                                    </div>
                                    <div className="comment-info-text">
                                      {selectedPatientData
                                        .refferalProviderEntity[0]
                                        .refferalProviderComment[1]
                                        ? selectedPatientData
                                            .refferalProviderEntity[0]
                                            .refferalProviderComment[1]
                                            .sender === "Provider"
                                          ? selectedPatientData
                                              .refferalProviderEntity[0]
                                              .refferalProviderComment[1]
                                              .comment
                                          : selectedPatientData
                                              .refferalProviderEntity[0]
                                              .refferalProviderComment[0]
                                              .comment
                                        : this.state.prelimCommentByProvider}
                                    </div>
                                  </Box>
                                </div>
                              )} */}
                              </div>
                            )}

                            {/* Transport work */}

                             {/* DME work */}

                             {this.state.refferalDmeEntity && (
                            <div>
                              <Box className="Decline-Ref-Form">
                                  <div className="comment-info-box">
                                    <div className="comment-title">
                                      Comments to Potential Providers{" "}
                                    </div>

                                    {this.state.refferalDmeEntity
                                      .dmeHeight ? (
                                      <Box
                                        width={1}
                                        display="flex"
                                        flexDirection="row"
                                        mb={2}
                                        className="comment-info"
                                      >
                                        <div className="comment-info-user">
                                          Height: 
                                        </div>
                                        <div className="comment-info-text">
                                          {
                                            this.state.refferalDmeEntity
                                              .dmeHeight
                                          }
                                        </div>
                                      </Box>
                                    ) : null}

                                    {this.state.refferalDmeEntity
                                      .dmeWeight ? (
                                      <Box
                                        width={1}
                                        display="flex"
                                        flexDirection="row"
                                        mb={2}
                                        className="comment-info"
                                      >
                                        <div className="comment-info-user">
                                          Weight: 
                                        </div>
                                        <div className="comment-info-text">
                                          {
                                            this.state.refferalDmeEntity
                                              .dmeWeight
                                          }
                                        </div>
                                      </Box>
                                    ) : null}
                                    {this.state.refferalDmeEntity
                                      .dmePatientAddress ? (
                                      <Box
                                        width={1}
                                        display="flex"
                                        flexDirection="row"
                                        mb={2}
                                        className="comment-info"
                                      >
                                        <div className="comment-info-user">
                                        Patient Address: 
                                        </div>
                                        <div className="comment-info-text">
                                          {
                                            this.state.refferalDmeEntity
                                              .dmePatientAddress
                                          }
                                        </div>
                                      </Box>
                                    ) : null}

                                    {this.state.refferalDmeEntity
                                      .dmeContactInformation ? (
                                      <Box
                                        width={1}
                                        display="flex"
                                        flexDirection="row"
                                        mb={2}
                                        className="comment-info"
                                      >
                                        <div className="comment-info-user">
                                        Contact Information: 
                                        </div>
                                        <div className="comment-info-text">
                                          {
                                            this.state.refferalDmeEntity
                                              .dmeContactInformation
                                          }
                                        </div>
                                      </Box>
                                    ) : null}

                                    <Box
                                      width={1}
                                      display="flex"
                                      flexDirection="row"
                                      mb={2}
                                      className="comment-info"
                                    >
                                      <div className="comment-info-user">
                                        Estimate Discharge Date: 
                                      </div>
                                      <div className="comment-info-text">
                                        {moment
                                          .utc(
                                            this.state.refferalDmeEntity
                                              .dmeEstimatestDischargeDate
                                          )
                                          // .add(offset, "minutes")
                                          .format("MM/DD/yyyy")}
                                      </div>
                                    </Box>
                                    {this.state.refferalDmeEntity
                                      .dmeInsurance ? (
                                      <Box
                                        width={1}
                                        display="flex"
                                        flexDirection="row"
                                        mb={2}
                                        className="comment-info"
                                      >
                                        <div className="comment-info-user">
                                        Insurance: 
                                        </div>
                                        <div className="comment-info-text">
                                          {
                                            this.state.refferalDmeEntity
                                              .dmeInsurance
                                          }
                                        </div>
                                      </Box>
                                    ) : null}

                                    {this.state.refferalDmeEntity
                                      .dmeInsuranceMemebrId ? (
                                      <Box
                                        width={1}
                                        display="flex"
                                        flexDirection="row"
                                        mb={2}
                                        className="comment-info"
                                      >
                                        <div className="comment-info-user">
                                        InsuranceMemebr Id: 
                                        </div>
                                        <div className="comment-info-text">
                                          {
                                            this.state.refferalDmeEntity
                                              .dmeInsuranceMemebrId
                                          }
                                        </div>
                                      </Box>
                                    ) : null}

                                    {this.state.refferalDmeEntity
                                      .dmeComments ? (
                                      <Box
                                        width={1}
                                        display="flex"
                                        flexDirection="row"
                                        mb={2}
                                        className="comment-info"
                                      >
                                        <div className="comment-info-user">
                                        Comments: 
                                        </div>
                                        <div className="comment-info-text">
                                          {
                                            this.state.refferalDmeEntity
                                              .dmeComments
                                          }
                                        </div>
                                      </Box>
                                    ) : null}
                                  
                                  {this.state.refferalDmeEntity
                                      .dmeEquipmentNeeds ? (
                                      <Box
                                        width={1}
                                        display="flex"
                                        flexDirection="row"
                                        mb={2}
                                        className="comment-info"
                                      >
                                        <div className="comment-info-user">
                                        Equipment Needs: 
                                        </div>
                                        <div className="comment-info-text">
                                          {
                                            this.state.refferalDmeEntity
                                              .dmeEquipmentNeeds
                                          }
                                        </div>
                                      </Box>
                                    ) : null}

                                  {this.state.refferalDmeEntity
                                      .dmeDiagonsis ? (
                                      <Box
                                        width={1}
                                        display="flex"
                                        flexDirection="row"
                                        mb={2}
                                        className="comment-info"
                                      >
                                        <div className="comment-info-user">
                                        Diagnosis and ICD-10 code: 
                                        </div>
                                        <div className="comment-info-text">
                                          {
                                            this.state.refferalDmeEntity
                                              .dmeDiagonsis
                                          }
                                        </div>
                                      </Box>
                                    ) : null}
                                  </div>
                              </Box>
                            </div>
                            )}
                              
                             {/* DME work */}
                            {selectedPatientData.refferalType == 1 && (
                              <div>
                                <div className="ref-text referral_text">
                                  This is a PRELIMINARY referral and is NOT
                                  finalized. When ready, a discharge planner
                                  based on the family decision will either
                                  FINALIZE that the referral is yours to service
                                  or DROP the referral from you. DROPPED
                                  referrals no longer need your assistance,
                                  typically due to family choice, another
                                  provider accepted the referral before you, or
                                  the service is no longer needed.{" "}
                                </div>
                                {/* { selectedPatientData.refferalProviderEntity[0]
                                  .refferalProviderComment.length &&
                                  selectedPatientData.refferalProviderEntity[0]
                                    .refferalProviderComment[0].comment &&
                                  selectedPatientData.refferalProviderEntity[0]
                                    .masterRefferalStatusEntity.id == 6 &&
                                  selectedPatientData.refferalProviderEntity[0]
                                    .refferalProviderComment.length == 1 &&
                                  (this.state.prelimCommentByProvider == "" ||
                                    selectedPatientData.refferalId !=
                                      this.state.commentPostReferralId) && ( */}
                                {selectedPatientData.refferalProviderEntity[0]
                                  .masterRefferalStatusEntity.id == 6 &&
                                  (this.state.prelimCommentByProvider == "" && this.state.selectedcomment=="" &&
                                    selectedPatientData.refferalId !=
                                      this.state.commentPostReferralId
                                    ) && (
                                    <Box className="Decline-Ref-Form">
                                      {selectedPatientData
                                        .refferalProviderEntity[0]
                                        .refferalProviderComment.length == 1 &&
                                        selectedPatientData
                                          .refferalProviderEntity[0]
                                          .refferalProviderComment[0]
                                          .comment && (
                                          <div className="comment-info-box">
                                            <div className="comment-title">
                                              Discharge planner comment{" "}
                                              {this.state.isPrelimCommentDone}
                                            </div>
                                            <Box
                                              width={1}
                                              display="flex"
                                              flexDirection="row"
                                              mb={2}
                                              className="comment-info"
                                            >
                                              <div className="comment-info-user">
                                                Discharge User
                                              </div>
                                              <div className="comment-info-text">
                                                {
                                                  selectedPatientData
                                                    .refferalProviderEntity[0]
                                                    .refferalProviderComment[0]
                                                    .comment
                                                }
                                              </div>
                                            </Box>
                                          </div>
                                        )}
                                      <form
                                        onSubmit={this.commentBoxModalSubmit}
                                      >
                                        <Box width={1} display="flex">
                                          <div className="comment-textbox ">
                                            <div className="comment-title">
                                              Your comment to the discharge
                                              planner. YOU MAY ONLY HAVE 1
                                              COMMENT (If FINALIZED, you can
                                              make ongoing comments). Make it
                                              clear if you can accept the
                                              patient. When ready, a discharge
                                              planner based on the family
                                              decision will either FINALIZE that
                                              the referral is yours to service
                                              or DROP the referral from you.
                                              DROPPED referrals no longer need
                                              your assistance typically due to
                                              family choice, another provider
                                              accepted the referral before you,
                                              or the service is no longer
                                              needed.
                                            </div>
                                            <TextField
                                              name="commentToDP"
                                              value={this.state.commentToDP}
                                              data-validators="isRequired"
                                              // label="Decline Reason"
                                              // disabled={true}
                                              multiline={true}
                                              rows={3}
                                              onChange={
                                                this.handleChangeInputForComment
                                              }
                                              className="Decline-TextField"
                                            />
                                          </div>

                                          <Button
                                            className="btn1 setbtnpos"
                                            type="submit"
                                            disabled={
                                              !this.validateForm() ||
                                              this.state.loading
                                            }
                                          >
                                            {this.state.loading && (
                                              <CircularProgress
                                                size={24}
                                                className="buttonProgress"
                                              />
                                            )}
                                            Submit
                                          </Button>
                                        </Box>
                                        <div className="w-100">
                                          <FormErrors
                                            show={!this.state.commentToDPValid}
                                            formErrors={this.state.formErrors}
                                            fieldName="commentToDP"
                                          />
                                        </div>
                                      </form>
                                    </Box>
                                  )}
                                {(selectedPatientData.refferalProviderEntity[0]
                                  .refferalProviderComment.length >= 1 ||
                                  (this.state.prelimCommentByProvider != "" &&
                                    selectedPatientData.refferalId ==
                                      this.state.commentPostReferralId)) && (
                                  <div className="comment-info-box">
                                    <div className="comment-title">
                                      Comments to Potential Providers
                                    </div>

                                    <Box
                                      width={1}
                                      display="flex"
                                      flexDirection="row"
                                      mb={2}
                                      className="comment-info"
                                    >
                                      <div className="comment-info-user">
                                        Discharge User{" "}
                                      </div>
                                      <div className="comment-info-text">
                                        {selectedPatientData
                                          .refferalProviderEntity[0]
                                          .refferalProviderComment[0].sender ===
                                        "Discharge Planner"
                                          ? selectedPatientData
                                              .refferalProviderEntity[0]
                                              .refferalProviderComment[0]
                                              .comment
                                          : selectedPatientData
                                              .refferalProviderEntity[0]
                                              .refferalProviderComment[1]
                                              .comment}
                                      </div>
                                    </Box>
                                    <Box
                                      width={1}
                                      display="flex"
                                      flexDirection="row"
                                      mb={2}
                                      className="comment-info"
                                    >
                                      <div className="comment-info-user">
                                        Provider Response
                                      </div>
                                      <div className="comment-info-text">
                                        {selectedPatientData
                                          .refferalProviderEntity[0]
                                          .refferalProviderComment[1]
                                          ? selectedPatientData
                                              .refferalProviderEntity[0]
                                              .refferalProviderComment[1]
                                              .sender === "Provider"
                                            ? selectedPatientData
                                                .refferalProviderEntity[0]
                                                .refferalProviderComment[1]
                                                .comment
                                            : selectedPatientData
                                                .refferalProviderEntity[0]
                                                .refferalProviderComment[0]
                                                .comment
                                          : this.state.prelimCommentByProvider}
                                      </div>
                                    </Box>
                                  </div>
                                )}
                              </div>
                            )}
                            {selectedPatientData.refferalProviderEntity[0]
                              .masterRefferalStatusEntity.id == 5 && (
                              <Box className="Decline-Ref-Form">
                                <TextField
                                  name="declineReason"
                                  value={
                                    selectedPatientData
                                      .refferalProviderEntity[0].comment
                                  }
                                  onChange={this.handleChangeInput}
                                  data-validators="isRequired"
                                  label="Decline Reason"
                                  disabled={true}
                                  multiline={true}
                                  rows={3}
                                  className="Decline-TextField"
                                />
                                <div className="ref-text">
                                  By{" "}
                                  {
                                    selectedPatientData
                                      .refferalProviderEntity[0].provider
                                      .providerName
                                  }{" "}
                                  :
                                  {this.dateUtility.format(
                                    new Date(
                                      selectedPatientData.refferalProviderEntity[0].formattedUpdatedDateTime
                                    ),
                                    "MM/dd/yyyy HH:mm:ss"
                                  )}
                                </div>
                              </Box>
                            )}
                          </Box>
                        ) : (
                          <Box
                            width={1}
                            display="flex"
                            flexDirection="column"
                            mb={2}
                          >
                            <div className={`list-item-content-Ref no-shadow`}>
                              No Patient found in referral.
                            </div>
                          </Box>
                        )}
                      </Scrollbars>
                    </div>
                    {this.state.referralStatusIds.includes(
                      selectedPatientData.refferalProviderEntity[0]
                        .masterRefferalStatusEntity.id
                    ) ? (
                      
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        width={1}
                        mb={3}
                        className="referral-right-actions"
                      >
                     {selectedPatientData.refferalProviderEntity[0].removeRefferal === true && ( 
                        <Button
                          className="btn3"
                          onClick={() =>
                            this.confirmModalOpen(
                              "Do you want to add the referral to dashboard?"
                            )
                          }
                        >
                          {this.state.loading &&
                          (
                            <CircularProgress
                              size={24}
                              className="buttonProgress"
                            />
                          )}
                         
                          Add Request to Dashboard
                          
                        </Button>
                       )}
                      

                      {selectedPatientData.refferalProviderEntity[0].removeRefferal === false && ( 
                        <Button
                          className="btn3"
                          onClick={() => {
                            this.confirmModalOpen("Do you want to remove the referral from dashboard?");
                            // After handling the modal, refresh the page
                           
                          }}
                        >
                          {this.state.loading &&
                          (
                            <CircularProgress
                              size={24}
                              className="buttonProgress"
                            />
                          )}
                         Remove Request from Dashboard
                          
                        </Button>
                      )}

                        {selectedPatientData.refferalType == 1 &&
                          (selectedPatientData.refferalProviderEntity[0]
                            .masterRefferalStatusEntity.id != 5 &&
                            selectedPatientData.refferalProviderEntity[0]
                              .masterRefferalStatusEntity.id != 7) && (
                            <Button
                              className="btn5"
                              onClick={this.refferalActionModalOpen}
                            >
                              Decline Referral
                            </Button>
                          )}
                      </Box>
                        
                    ) : (
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        width={1}
                        mb={3}
                        className="referral-right-actions"
                      >
                        <Button
                          className="btn3"
                          onClick={this.acceptedReferralModalOpen}
                        >
                          {selectedPatientData.refferalType == 1
                            ? "ACKNOWLEDGE"
                            : "Accept Referral"}
                        </Button>
                        <Button
                          className="btn5"
                          onClick={this.refferalActionModalOpen}
                        >
                          Decline Referral
                        </Button>
                      </Box>
                    )}
                  </div>
                ) : this.state.loading ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    width={1}
                    className="ref-scrollbox"
                    style={{ height: "calc(100vh - 198px)" }}
                  >
                    <div className="select-referral-text">
                      Loading<br></br>
                      please wait...
                    </div>
                  </Box>
                ) : (
                  <Box
                    display="flex"
                    justifyContent="center"
                    width={1}
                    className="ref-scrollbox"
                    style={{ height: "calc(100vh - 198px)" }}
                  >
                    <div className="select-referral-text">
                      Select Referral<br></br>
                      to View Details
                    </div>
                  </Box>
                )}
              </div>
            </Box>
          </Box>
        ) : null}
      </div>
    );
  }
}

export const PatientList = connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientListClass);
export default PatientList;
