/*!

=========================================================
* Material Dashboard React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes, { element } from "prop-types";
import classNames from "classnames";

// @material-ui/core components
import Poppers from "@material-ui/core/Popper";
import {
  Icon,
  styles,
  MenuItem,
  MenuList,
  Grow,
  Paper,
  ClickAwayListener,
  Hidden,
  Divider,
  withStyles,
  Avatar,
  Select,
  FormGroup,
  InputLabel,
  FormControl,
  Input,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Slide,
  IconButton,
  LinearProgress,
} from "@material-ui/core/";
import CloseIcon from "@material-ui/icons/Close";
import Person from "@material-ui/icons/Person";
import HomeIcon from "@material-ui/icons/Home";
import CalendarIcon from "@material-ui/icons/CalendarTodayOutlined";
import PowerOff from "@material-ui/icons/PowerSettingsNew";
import Notifications from "@material-ui/icons/Notifications";
import Dashboard from "@material-ui/icons/Dashboard";
import Search from "@material-ui/icons/Search";
import SelectAllTwoTone from "@material-ui/icons/SelectAllTwoTone";

import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import enMsg from "__helpers/locale/en/en";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import dummy from "assets/img/dummy.png";
import { sizeHeight } from "@material-ui/system";
import { Link } from "react-router-dom";
import {
  baseRoutes,
  basePath,
  providerBaseRoutes,
  providerUserBaseRoutes,
  dpBaseRoutes,
} from "base-routes";
import { layout } from "admin-routes";
import { userService } from "_services/user.service";
import { DEFAULT_PROFILE_IMG, NO_USERNAME } from "__helpers/constants";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer.jsx";
import { STORED_ADNETWORK } from "__helpers/constants";
import { SELECTED_ADNETWORK } from "__helpers/constants";
import { connect } from "react-redux";
import { FACILITY_NAME } from "__helpers/constants";
import Box from "@material-ui/core/Box";
import ToggleButton from "@material-ui/lab/ToggleButton";

import { apiPath } from "api";
import {
  generalAuthTokenHeader,
  multimediaAuthTokenHeader,
  generalOctetAuthTokenHeader,
} from "__helpers/auth-header";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { USER_INFO, ADMIN_USER } from "__helpers/constants";
import Scrollbars from "react-custom-scrollbars";
import BAAComponent from "views/CmsPages/BAAComponent";
import TaskTemplate from "views/dpViews/SinglePatientDetail/TaskTemplate";
const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    articles: state.articles,
    adNetwork: state.adNetwork,
    loadingFlag: state.loadingFlag,
    adCategories: state.adCategories,
    adBrands: state.adBrands,
    adStatus: state.adStatus,
    adMedium: state.adMedium,
    adType: state.adType,
    assetType: state.assetType,
    assetDisplayType: state.assetDisplayType,
    campaignStatus: state.campaignStatus,
    advertisements: state.advertisements,
    campaings: state.campaings,
    assets: state.assets,
    vendors: state.vendors,
    timestamp: state.timestamp,
    reduxLoadFlag: state.reduxLoadFlag,
  };
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});
class AdminNavbarLinksClass extends React.Component {
  constructor(props) {
    super(props);
    let adNetworksData = JSON.parse(localStorage.getItem(STORED_ADNETWORK));
    let selectedAdnetworkData = JSON.parse(
      localStorage.getItem(SELECTED_ADNETWORK)
    );
    let selectedAdnetwork = selectedAdnetworkData
      ? selectedAdnetworkData.adNetworkId
      : "";
    this._isMounted = false;
    let facilityName = FACILITY_NAME;
    let spinner = document.getElementById("loadingSpinner");
    this.state = {
      openNotifcation: false,
      openProfile: false,
      userDetail: {},
      profileImage: DEFAULT_PROFILE_IMG,
      username: NO_USERNAME,
      dpFacilityName: "",
      providerName: "",
      adnetworkId: selectedAdnetwork ? selectedAdnetwork : "",
      adNetworkJson: adNetworksData ? adNetworksData : [],
      adnetworkData: selectedAdnetworkData ? selectedAdnetworkData : [],
      loadSpinner: false,
      spinner: spinner,
      loading: false,
      reduxLoadFlag: false,
      role: props.role,
      logo: props.logo,
      logoText: props.logoText,
      referralType: localStorage.getItem("selectedReferral")
        ? localStorage.getItem("selectedReferral")
        : "dashboard",
      providerUserUrl: "",
      newNotification: "",
      reportMenu: false,
      modalType: null,
      reportModal: false,
      isBaaOpen: false,
      baaFrom: "Admin Nav",
      
    };
    this.handleToggleProfile = this.handleToggleProfile.bind(this);
    this.selectReferrals = this.selectReferrals.bind(this);
    this.handleReportMenu = this.handleReportMenu.bind(this);
    this.openReportModal = this.openReportModal.bind(this);
    this.downloadReport = this.downloadReport.bind(this);
    this.closeReportModal = this.closeReportModal.bind(this);
    this.handleOpenTaskTemplatePopup = this.handleOpenTaskTemplatePopup.bind(this);
    this.handleCloseTaskTemplatePopup = this.handleCloseTaskTemplatePopup.bind(this);
  }

  componentWillUnmount() {
    this._isMounted = false;
    Object.getPrototypeOf(this).constructor.STATE = this.state;
  }
  componentDidUpdate() {
    if (
      this.props.reduxLoadFlag != undefined &&
      this.state.reduxLoadFlag != this.props.reduxLoadFlag
    ) {
      let url = window.location.href;
      let lastKey = url.lastIndexOf("/");
      lastKey = url.substring(lastKey + 1);
      this.setState({ providerUserUrl: lastKey });
      let userInfo = {};
      let providerName = "";
      let adnetworkData = [];
      if (this.props.userInfo) {
        userInfo = this.props.userInfo;
        providerName = this.props.userInfo.provider.providerName;
      }
      this.setState({
        reduxLoadFlag: this.props.reduxLoadFlag,
        userDetail: userInfo,
        providerName: providerName,
        profileImage: userInfo ? userInfo.imageRef : DEFAULT_PROFILE_IMG,
        username: userInfo
          ? userInfo.firstName + " " + userInfo.lastName
          : NO_USERNAME,
      });
    }
  }
  componentDidMount() {
    this.setState({
      isBaaOpen: false,
    });
    let userInfo = JSON.parse(localStorage.getItem(USER_INFO));
    let providerName = userInfo.provider && userInfo.provider.providerName;
    let dpFacilityName =
      userInfo.dischargePlanner &&
      userInfo.dischargePlanner.facility &&
      userInfo.dischargePlanner.facility.facilityName;
    if (userInfo.usrType != ADMIN_USER) {
      // clearInterval(this.interval);
      this.interval = setInterval(() => this.checkNotification(), 7000);
    }
    this._isMounted = true;
    if (
      this.props.reduxLoadFlag != undefined &&
      this.state.reduxLoadFlag != this.props.reduxLoadFlag
    ) {
      let userInfo = {};
      if (this.props.userInfo) {
        userInfo = this.props.userInfo;
      }
      this.setState({
        reduxLoadFlag: this.props.reduxLoadFlag,
        userDetail: userInfo,
        dpFacilityName: dpFacilityName,
        providerName: providerName,
        profileImage: userInfo ? userInfo.imageRef : DEFAULT_PROFILE_IMG,
        username: userInfo
          ? userInfo.firstName + " " + userInfo.lastName
          : NO_USERNAME,
      });
    }
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    return null;
  }
  checkNotification = () => {
    let showNotification = {};
    let apiUrl = apiPath.isNewNotification;
    const response = fetch(apiUrl, {
      method: "GET",
      headers: generalAuthTokenHeader(),
    })
      .then((response) => {
        if (response.status === 400) {
        } else if (response.ok) {
        } else {
        }
        return response.json();
      })
      .then((data) => {
        this.setState({ newNotification: data.newNotificationExist });
      })
      .catch((error) => {
        showNotification = {
          title: "Referral",
          message: "Bad response from server",
          type: "danger",
        };
      });
    userService.showNotification(showNotification);
  };
  handleToggleNotification = () => {
    this._isMounted &&
      this.setState((state) => ({
        openNotifcation: !state.openNotifcation,
        reportModal: false,
      }));
  };
  handleCloseNotification = (event) => {
    if (this.anchorNotification.contains(event.target)) {
      return;
    }
    this.setState({
      openNotifcation: false,
      reportMenu: false,
    });
  };
handleOpenTaskTemplatePopup() {
    this.setState({ showTaskTemplatePopup: true });
}

handleCloseTaskTemplatePopup() {
    this.setState({ showTaskTemplatePopup: false });
}

  handleSelectAdnetwork(eventId) {}
  selectReferrals = (selectReferrals) => {
    this.setState(
      {
        referralType: selectReferrals,
      },
      localStorage.setItem("selectedReferral", selectReferrals)
    );
  };
  handleToggleProfile = () => {
    this.setState((state) => ({ openProfile: !state.openProfile }));
  };
  handleCloseProfile = (event) => {
    if (this.anchorProfile.contains(event.target)) {
      return;
    }
    this.setState({ openProfile: false });
  };
  handleLogout = () => {
    window.location.replace(baseRoutes.login.path);
    userService.logout();
    return true;
  };
  handleRefReport = () => {
    window.location.href=( basePath +
                                      dpBaseRoutes.ReferralReportsDischargePlanner.path );
    userService.logout();
    return true;
  };

  handleReportMenu() {
    let reportMenu = this.state.reportMenu ? false : true;
    this.setState({
      reportMenu: reportMenu,
    });
  }
  openReportModal(modalType) {
    this.setState({
      modalType: modalType,
      reportModal: true,
    });
  }
  closeReportModal() {
    this.setState({
      reportModal: false,
    });
  }
  async downloadReport() {
    let showNotification = {};
    try {
      let modalType = "1";
      if (this.state.modalType == "currentPatient") {
        modalType = "1";
      } else if (this.state.modalType == "closedPatient") {
        modalType = "2";
      } else if (this.state.modalType == "dischargedPatient") {
        modalType = "3";
      } else {
        showNotification = {
          title: "Invalid Action",
          message: "Please try again.",
          type: "danger",
        };
        userService.showNotification(showNotification);
        this.setState({
          reportModal: false,
        });
        return true;
      }
      this.setState({
        loading: true,
      });
      let apiUrl = apiPath.downloadReportPdf + "?reportType=" + modalType;
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: generalOctetAuthTokenHeader(),
      })
        .then((response) => {
          if (response.status === 400) {
            showNotification = {
              title: enMsg.sessionExpireTitle,
              message: enMsg.sessionExpire,
              type: "warning",
            };
            return Promise.reject("");
          } else if (response.status === 401) {
            showNotification = {
              title: enMsg.connectionFailed,
              message: enMsg.networkFailedError,
              type: "danger",
            };
          } else if (response.ok) {
            return response.blob();
          } else {
            showNotification = {
              title: enMsg.connectionFailed,
              message: enMsg.networkFailedError,
              type: "danger",
            };
          }
          this.setState({
            loading: false,
          });
          return response.json();
        })
        .then((data) => {
          if (data.detail) {
            showNotification = {
              title: enMsg.connectionFailed,
              message: data.detail,
              type: "danger",
            };
          } else {
            let fileName =
              this.state.modalType + "Report_" + Date.now() + ".pdf";
            const blob = new Blob([data]);
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.href = url;
            a.download = fileName;
            a.target = "_blank";
            a.rel = "noopener noreferrer";
            a.click();
          }
          this.setState({
            loading: false,
          });
          return true;
        })
        .catch((error) => {
          showNotification = {
            title: enMsg.connectionFailed,
            message: enMsg.networkFailedError,
            type: "danger",
          };
          this.setState({
            loading: false,
          });
        });
    } catch (error) {
      showNotification = {
        title: enMsg.connectionFailed,
        message: enMsg.networkFailedError,
        type: "danger",
      };
      this.setState({
        loading: false,
      });
    }
    userService.showNotification(showNotification);
  }

  openBaaHandler = (status) => {
    console.log("BAAHandler", status);
    if (status === "open") {
      this.setState({
        isBaaOpen: true,
      });
    } else {
      this.setState({
        isBaaOpen: false,
      });
    }
  };
  render() {
    const { classes } = this.props;
    const {
      openNotifcation,
      openProfile,
      adnetworkId,
      adnetworkData,
      adNetworkJson,
    } = this.state;
    const user_image = "user-image";
    const styleuser = {
      borderRadius: "50%",
      width: "35px",
      height: "35px",
    };
    const userNameStyle = {
      float: "right",
      display: "block",
      width: "calc(100% - 50px)",
      color: "#000",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "normal",
      marginTop: "10px",
    };
    const logoutStyle = {
      float: "right",
      display: "block",
      width: "calc(100% - 50px)",
      color: "#2680EB",
      fontSize: "12px",
      fontWeight: "400",
      lineHeight: "normal",
      marginTop: "4px",
    };

    return (
      <div className="mobileMenuSidebarxxx">
        <BAAComponent
          open={this.state.isBaaOpen}
          handleBAAAgree={this.handleBAAAgree}
          baaFromPage={this.state.baaFrom}
          openBaaHandler={this.openBaaHandler}
        />

        <Dialog
          open={this.state.reportModal}
          maxWidth={"sm"}
          onClose={this.closeReportModal}
          aria-labelledby="provider-dialog-title"
          className="add-modal front-modal ProviderInfo "
          TransitionComponent={Transition}
        >
          <DialogTitle className="add-modal-title" id="provider-dialog-title">
            <div className="providerName">Report Viewer</div>
            <IconButton
              className="closeButton"
              aria-label="close"
              onClick={this.closeReportModal}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Scrollbars
            //style={{ height: "600px" }}
            autoHide={false}
            universal={true}
            autoHeight={true}
            autoHeightMin={0}
            autoHeightMax={600}
          >
            <DialogContent>
              {this.state.loading ? <LinearProgress color="primary" /> : ""}
              <Box>
                <h3>Executive Summary</h3>
                {this.state.modalType == "currentPatient" ? (
                  <Box>
                    <p>Current Dashboard Patient</p>
                    All patients with status of (Planning, Planned, Discharged,
                    Issue. Inactive)
                  </Box>
                ) : this.state.modalType == "closedPatient" ? (
                  <Box>
                    <p>Closed Patients</p>
                    All patients with status of (Closed)
                  </Box>
                ) : (
                  <Box>
                    <p>Discharged in last month</p>
                    All patients with status of (Discharge) from last Month
                  </Box>
                )}
              </Box>
            </DialogContent>
            <DialogActions className="modal-actions" display="flex" width={1}>
              <Button onClick={this.downloadReport} className="btn1">
                Download Report
              </Button>
            </DialogActions>
          </Scrollbars>
        </Dialog>
        {this.state.role == "admin" ? (
          // admin hader
          <div className={classes.manager + " profile-box"}>
            <Link
              href={basePath + baseRoutes.profile.path} ///${user.username}
              to={basePath + baseRoutes.profile.path} ///${user.username}
              style={userNameStyle}
            >
              {this.state.username}
            </Link>
            <Link to="#" onClick={this.handleLogout} style={logoutStyle}>
              Logout
            </Link>
            <Link
              href={basePath + baseRoutes.profile.path} ///${user.username}
              to={basePath + baseRoutes.profile.path} ///${user.username}
            >
              <Button
                buttonRef={(node) => {
                  this.anchorProfile = node;
                }}
                color={window.innerWidth > 959 ? "transparent" : "white"}
                justIcon={window.innerWidth > 959}
                simple={!(window.innerWidth > 959)}
                aria-owns={openNotifcation ? "profile-menu-list-grow" : null}
                aria-haspopup="true"
                onClick={this.handleToggleProfile}
                className={classes.buttonLink + " profile-img-button"}
              >
                {" "}
                <span>
                  <Avatar
                    alt=""
                    src={this.state.profileImage}
                    className={user_image}
                    style={styleuser}
                  />
                  {this.state.username == NO_USERNAME ? (
                    <span>
                      <CircularProgress
                        size={24}
                        className="buttonProgress"
                        color="primary"
                        style={{ color: "#01A7A6" }}
                      />
                    </span>
                  ) : (
                    ""
                  )}
                </span>
              </Button>
            </Link>
          </div>
        ) : // dp header
        this.state.role == "dp" ? (
          <div className={classes.manager + " provider-tabs w-100"}>
            <Box display="flex" className="flex-box">
              <Box width={1 / 2}>
                <div>
                  <Link
                    // to={dpBaseRoutes.dashboard.path}
                    to={{
                      pathname: `${dpBaseRoutes.dashboard.path}`,
                      state: {
                        resetDashboard: true,
                      },
                    }}
                  >
                    <div className={classes.logoImage + " Sidebar-logoImage"}>
                      <img
                        src={this.state.logo}
                        alt="logo"
                        className={classes.img}
                      />
                    </div>
                  </Link>
                  <Link
                    // to={dpBaseRoutes.dashboard.path}
                    to={{
                      pathname: `${dpBaseRoutes.dashboard.path}`,
                      state: {
                        resetDashboard: true,
                      },
                    }}
                  >
                    <div>
                      <HomeIcon className="HomeLink" />
                    </div>
                  </Link>
                </div>
              </Box>
              <Box width={1 / 2}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  className="right-tabs"
                >
                  <Box p={1}>
                    <Link
                      // to={dpBaseRoutes.dashboard.path}
                      to={{
                        pathname: `${basePath}${dpBaseRoutes.calendar.path}`,
                      }}
                    >
                      Calendar
                    </Link>
                  </Box>

                  <Box p={1}>
                    <div className="tab-link-1">
                      <Link
                        href={basePath + dpBaseRoutes.notification.path}
                        to={basePath + dpBaseRoutes.notification.path}
                      >
                        <span>Notifications</span>
                        {this.state.newNotification && (
                          <Icon className="fa fa-circle" aria-hidden="true" />
                        )
                        // <div>asdsa</div>
                        }
                      </Link>
                    </div>
                  </Box>
                  <Box p={1}>
                    <div className="tab-link-1">
                      <span>{this.state.dpFacilityName}</span>
                    </div>
                  </Box>
                  <Box p={1} style={{ display: "none" }}>
                    <div className="tab-link-2">
                      <Link
                        href={basePath + baseRoutes.profile.path} ///${user.username}
                        to={basePath + baseRoutes.profile.path} ///${user.username}
                      >
                        <span>{this.state.facilityName}</span>{" "}
                        <Icon className="fa fa-caret-down" aria-hidden="true" />
                      </Link>
                    </div>
                  </Box>
                  <Box p={1}>
                    <div className="tab-link-3">
                      <Button
                        onClick={this.handleToggleNotification}
                        buttonRef={(node) => {
                          this.anchorNotification = node;
                        }}
                        color={"transparent"}
                        aria-owns={
                          openNotifcation ? "notification-menu-list-grow" : null
                        }
                        aria-haspopup="true"
                        className="buttonlinkHeader"
                      >
                        <span className={"name-text"}>
                          {this.state.username}
                        </span>{" "}
                        <Icon className="fa fa-caret-down" aria-hidden="true" />
                        <span
                          className={
                            classes.notifications +
                            " AdnetworkSelect-notifications count"
                          }
                        >
                          {adNetworkJson ? adNetworkJson.length : 0}
                        </span>
                      </Button>
                      <Poppers
                        open={openNotifcation}
                        anchorEl={this.anchorNotification}
                        transition
                        disablePortal
                        className={
                          classNames({
                            [classes.popperClose]: !openNotifcation,
                          }) +
                          " " +
                          classes.popperNavXX +
                          " AdnetworkSelect-popperNav"
                        }
                        style={{ zIndex: "9999999" }}
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            id="notification-menu-list-grow"
                            style={{
                              transformOrigin:
                                placement === "bottom"
                                  ? "center top"
                                  : "center bottom",
                            }}
                          >
                            <Paper className="user-menu-box">
                              <ClickAwayListener
                                onClickAway={this.handleCloseNotification}
                              >
                                <MenuList role="menu">
                                  <MenuItem>
                                    <Link
                                      href={
                                        basePath +
                                        baseRoutes.profile.path +
                                        "?mode=1"
                                      } ///${user.username}
                                      to={
                                        basePath +
                                        baseRoutes.profile.path +
                                        "?mode=1"
                                      } ///${user.username}
                                      onClick={this.handleCloseNotification}
                                      className="menu-a"
                                    >
                                      <span>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                        >
                                          <path
                                            id="user-circle_2_"
                                            data-name="user-circle (2)"
                                            d="M8,8A8,8,0,0,0,8,24a8.109,8.109,0,0,0,7.392-4.936A7.6,7.6,0,0,0,16,16,8,8,0,0,0,8,8Zm0,3.1a2.839,2.839,0,1,1-2.839,2.839A2.839,2.839,0,0,1,8,11.1Zm0,11.1a6.182,6.182,0,0,1-4.726-2.2,3.6,3.6,0,0,1,3.177-1.929.789.789,0,0,1,.229.035A4.271,4.271,0,0,0,8,18.323,4.255,4.255,0,0,0,9.319,18.1a.789.789,0,0,1,.229-.035,3.6,3.6,0,0,1,3.177,1.929A6.182,6.182,0,0,1,8,22.194Z"
                                            transform="translate(0 -8)"
                                            fill="#333"
                                          />
                                        </svg>
                                      </span>
                                      <span>Profile</span>
                                    </Link>
                                  </MenuItem>
                                  {/* <MenuItem>
                                    <Link
                                      href={
                                        basePath +
                                        baseRoutes.profile.path +
                                        "?mode=2"
                                      } ///${user.username}
                                      to={
                                        basePath +
                                        baseRoutes.profile.path +
                                        "?mode=2"
                                      } ///${user.username}
                                      onClick={this.handleCloseNotification}
                                      className="menu-a"
                                    >
                                      <span>
                                        <i className="fa fa-user-plus"></i>
                                      </span>
                                      <span>Invite</span>
                                    </Link>
                                  </MenuItem> */}
                                  <MenuItem className="reporter-menu">
                                    <div
                                      to="#"
                                      onClick={() => this.handleReportMenu()}
                                      className="menu-a"
                                    >
                                      <span>
                                        <i className="fa fa-file"></i>
                                      </span>
                                      <span>Report Viewer</span>
                                    </div>
                                    {this.state.reportMenu ? (
                                      <MenuList
                                        role="menu"
                                        className="header-submenu"
                                      >
                                        <MenuItem
                                          onClick={() =>
                                            this.openReportModal(
                                              "currentPatient"
                                            )
                                          }
                                        >
                                          Current Patients
                                        </MenuItem>
                                        <MenuItem
                                          onClick={() =>
                                            this.openReportModal(
                                              "closedPatient"
                                            )
                                          }
                                        >
                                          Closed Patients
                                        </MenuItem>
                                        <MenuItem
                                          onClick={() =>
                                            this.openReportModal(
                                              "dischargedPatient"
                                            )
                                          }
                                        >
                                          Discharged Last Month
                                        </MenuItem>
                                        <MenuItem  onClick={this.handleRefReport}>
                                       
                                         Referral Reports
                                        
                                        </MenuItem>
                                      </MenuList>
                                    ) : null}
                                  </MenuItem>

                                  <MenuItem>
                                    <Link
                                      to={{
                                        pathname: "https://maxmrj.com/support/",
                                      }}
                                      onClick={this.handleCloseNotification}
                                      className="menu-a"
                                      target={"_blank"}
                                      rel={"noopener noreferrer"}
                                    >
                                      <span>
                                        <i className="fa fa-user-plus"></i>
                                      </span>
                                      <span>Support</span>
                                    </Link>
                                  </MenuItem>
                                  <MenuItem>
                                  <Link
                                    to={
                                      basePath +
                                      dpBaseRoutes.TaskTemplate.path 
                                    } 
                                    onClick={this.handleCloseNotification}
                                    className="menu-a"
                                  >
                                  <span>
                                  <i className="fa fa-list"></i>
                                  </span>
                                  <span>TaskTemplates</span>
                                </Link>
                                  </MenuItem>
                                  <MenuItem>
                                    <Link
                                      to={{
                                        pathname:
                                          "https://maxmrj.com/privacy-policy/",
                                      }}
                                      onClick={this.handleCloseNotification}
                                      className="menu-a"
                                      target={"_blank"}
                                      rel={"noopener noreferrer"}
                                    >
                                      <span>
                                        <i className="fa fa-file-text-o"></i>
                                      </span>
                                      <span>Privacy Policy</span>
                                    </Link>
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      this.openBaaHandler("open");
                                    }}
                                  >
                                    <Link to="#" className="menu-a">
                                      <span>
                                        <i className="fa fa-file-text-o"></i>
                                      </span>
                                      <span>BAA Policy</span>
                                    </Link>
                                  </MenuItem>
                                  <MenuItem
                                    onClick={this.handleCloseNotification}
                                  >
                                    <Link
                                      to="#"
                                      onClick={this.handleLogout}
                                      className="menu-a"
                                    >
                                      <span>
                                        <i className="fa fa-power-off"></i>
                                      </span>
                                      <span>Logout</span>
                                    </Link>
                                  </MenuItem>
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Poppers>
                    </div>
                  </Box>
                </Box>
              </Box>
            </Box>
          </div>
        ) : // provider user header
        this.state.role == "providerUser" ? (
          <div className={classes.manager + " provider-tabs w-100"}>
            <Hidden xsDown implementation="css" className=" admin-h ">
              <Box display="flex" className="flex-box">
                <Box width={1 / 3}>
                  <div>
                    <Link to={providerUserBaseRoutes.dashboard.path}>
                      <div className={classes.logoImage + " Sidebar-logoImage"}>
                        <img
                          src={this.state.logo}
                          alt="logo"
                          className={classes.img}
                        />
                      </div>
                    </Link>
                    <Link to={providerUserBaseRoutes.dashboard.path}>
                      <div>
                        <HomeIcon className="HomeLink" />
                      </div>
                    </Link>
                  </div>
                </Box>
                {this.state.providerUserUrl &&
                  this.state.providerUserUrl == "patientListProvider" && (
                    <Box width={1 / 3} className="text-center">
                      <div className="referrals-toggle">
                        <Link
                          href={
                            basePath + providerUserBaseRoutes.patientList.path
                          } ///${user.username}
                          to={
                            basePath + providerUserBaseRoutes.patientList.path
                          } ///${user.username}
                          className={
                            this.state.referralType == "all"
                              ? "link1 link2 active-button"
                              : "link1 link2 inactive-button"
                          }
                          onClick={(event) => this.selectReferrals("all")}
                        >
                          All Referrals
                        </Link>
                      </div>
                    </Box>
                  )}

                <Box width={1 / 3}>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    className="right-tabs"
                  >
                    <Box p={1}>
                      <div className="tab-link-1">
                        <span>{this.state.providerName}</span>
                      </div>
                    </Box>
                    <Box p={1}>
                      <div className="tab-link-1">
                        <Link
                          href={basePath + dpBaseRoutes.notification.path}
                          to={basePath + dpBaseRoutes.notification.path}
                        >
                          <span>Notifications</span>
                          {this.state.newNotification && (
                            <Icon className="fa fa-circle" aria-hidden="true" />
                          )
                          // <div>asdsa</div>
                          }
                        </Link>
                      </div>
                    </Box>
                    <Box p={1} style={{ display: "none" }}>
                      <div className="tab-link-2">
                        <Link
                          href={basePath + baseRoutes.profile.path} ///${user.username}
                          to={basePath + baseRoutes.profile.path} ///${user.username}
                        >
                          <span>{this.state.facilityName}</span>{" "}
                          <Icon
                            className="fa fa-caret-down"
                            aria-hidden="true"
                          />
                        </Link>
                      </div>
                    </Box>
                    <Box p={1}>
                      <div className="tab-link-3">
                        <Button
                          onClick={this.handleToggleNotification}
                          buttonRef={(node) => {
                            this.anchorNotification = node;
                          }}
                          color={"transparent"}
                          aria-owns={
                            openNotifcation
                              ? "notification-menu-list-grow"
                              : null
                          }
                          aria-haspopup="true"
                          className="buttonlinkHeader"
                        >
                          <span className={"name-text"}>
                            {this.state.username}
                          </span>{" "}
                          <Icon
                            className="fa fa-caret-down"
                            aria-hidden="true"
                          />
                          <span
                            className={
                              classes.notifications +
                              " AdnetworkSelect-notifications count"
                            }
                          >
                            {adNetworkJson ? adNetworkJson.length : 0}
                          </span>
                        </Button>
                        <Poppers
                          open={openNotifcation}
                          anchorEl={this.anchorNotification}
                          transition
                          disablePortal
                          className={
                            classNames({
                              [classes.popperClose]: !openNotifcation,
                            }) +
                            " " +
                            classes.popperNavXX +
                            " AdnetworkSelect-popperNav"
                          }
                          style={{ zIndex: "9999999" }}
                        >
                          {({ TransitionProps, placement }) => (
                            <Grow
                              {...TransitionProps}
                              id="notification-menu-list-grow"
                              style={{
                                transformOrigin:
                                  placement === "bottom"
                                    ? "center top"
                                    : "center bottom",
                              }}
                            >
                              <Paper className="user-menu-box">
                                <ClickAwayListener
                                  onClickAway={this.handleCloseNotification}
                                >
                                  <MenuList role="menu">
                                    <MenuItem>
                                      <Link
                                        href={
                                          basePath + baseRoutes.profile.path
                                        } ///${user.username}
                                        to={basePath + baseRoutes.profile.path} ///${user.username}
                                        onClick={this.handleCloseNotification}
                                        className="menu-a"
                                      >
                                        <span>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                          >
                                            <path
                                              id="user-circle_2_"
                                              data-name="user-circle (2)"
                                              d="M8,8A8,8,0,0,0,8,24a8.109,8.109,0,0,0,7.392-4.936A7.6,7.6,0,0,0,16,16,8,8,0,0,0,8,8Zm0,3.1a2.839,2.839,0,1,1-2.839,2.839A2.839,2.839,0,0,1,8,11.1Zm0,11.1a6.182,6.182,0,0,1-4.726-2.2,3.6,3.6,0,0,1,3.177-1.929.789.789,0,0,1,.229.035A4.271,4.271,0,0,0,8,18.323,4.255,4.255,0,0,0,9.319,18.1a.789.789,0,0,1,.229-.035,3.6,3.6,0,0,1,3.177,1.929A6.182,6.182,0,0,1,8,22.194Z"
                                              transform="translate(0 -8)"
                                              fill="#333"
                                            />
                                          </svg>
                                        </span>
                                        <span>Profile</span>
                                      </Link>
                                    </MenuItem>
                                    <MenuItem>
                                      <Link
                                        to={{
                                          pathname:
                                            "https://maxmrj.com/support/",
                                        }}
                                        onClick={this.handleCloseNotification}
                                        className="menu-a"
                                        target={"_blank"}
                                        rel={"noopener noreferrer"}
                                      >
                                        <span>
                                          <i className="fa fa-user-plus"></i>
                                        </span>
                                        <span>Support</span>
                                      </Link>
                                    </MenuItem>
                                    <MenuItem>
                                      <Link
                                        to={{
                                          pathname:
                                            "https://maxmrj.com/privacy-policy/",
                                        }}
                                        onClick={this.handleCloseNotification}
                                        className="menu-a"
                                        target={"_blank"}
                                        rel={"noopener noreferrer"}
                                      >
                                        <span>
                                          <i className="fa fa-file-text-o"></i>
                                        </span>
                                        <span>Privacy Policy</span>
                                      </Link>
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        this.openBaaHandler("open");
                                      }}
                                    >
                                      <Link to="#" className="menu-a">
                                        <span>
                                          <i className="fa fa-file-text-o"></i>
                                        </span>
                                        <span>BAA Policy</span>
                                      </Link>
                                    </MenuItem>
                                    <MenuItem
                                      onClick={this.handleCloseNotification}
                                    >
                                      <Link
                                        to="#"
                                        onClick={this.handleLogout}
                                        className="menu-a"
                                      >
                                        <span>
                                          <i className="fa fa-power-off"></i>
                                        </span>
                                        <span>Logout</span>
                                      </Link>
                                    </MenuItem>
                                  </MenuList>
                                </ClickAwayListener>
                              </Paper>
                            </Grow>
                          )}
                        </Poppers>
                      </div>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Hidden>
            <Hidden className=" admin-h" smUp implementation="css">
              <Box>
                <MenuList role="menu">
                  <Link
                    href={basePath + providerBaseRoutes.dashboard.path}
                    to={basePath + providerBaseRoutes.dashboard.path}
                    onClick={this.props.handleDrawerToggle}
                    className="menu-a"
                  >
                    <span>
                      <HomeIcon className="HomeLink" />
                    </span>
                    <span>Home</span>
                  </Link>
                  <Link
                    href={basePath + providerBaseRoutes.profile.path} ///${user.username}
                    to={basePath + providerBaseRoutes.profile.path} ///${user.username}
                    onClick={this.props.handleDrawerToggle}
                    className="menu-a"
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                      >
                        <path
                          id="user-circle_2_"
                          data-name="user-circle (2)"
                          d="M8,8A8,8,0,0,0,8,24a8.109,8.109,0,0,0,7.392-4.936A7.6,7.6,0,0,0,16,16,8,8,0,0,0,8,8Zm0,3.1a2.839,2.839,0,1,1-2.839,2.839A2.839,2.839,0,0,1,8,11.1Zm0,11.1a6.182,6.182,0,0,1-4.726-2.2,3.6,3.6,0,0,1,3.177-1.929.789.789,0,0,1,.229.035A4.271,4.271,0,0,0,8,18.323,4.255,4.255,0,0,0,9.319,18.1a.789.789,0,0,1,.229-.035,3.6,3.6,0,0,1,3.177,1.929A6.182,6.182,0,0,1,8,22.194Z"
                          transform="translate(0 -8)"
                          fill="#333"
                        />
                      </svg>
                    </span>
                    <span>Profile</span>
                  </Link>
                  <Link
                    href={basePath + dpBaseRoutes.notification.path}
                    to={basePath + dpBaseRoutes.notification.path}
                    onClick={this.props.handleDrawerToggle}
                  >
                    <span>Notifications</span>
                    {this.state.newNotification && (
                      <Icon className="fa fa-circle" aria-hidden="true" />
                    )}
                  </Link>
                  <Link to="#" onClick={this.handleLogout} className="menu-a">
                    <span>
                      <i className="fa fa-power-off"></i>
                    </span>
                    <span>Logout</span>
                  </Link>
                </MenuList>
              </Box>
            </Hidden>
          </div>
        ) : (
          // provider header
          <div className={classes.manager + " provider-tabs w-100"}>
            <Hidden xsDown implementation="css" className=" admin-h ">
              <Box display="flex" className="flex-box">
                <Box width={1 / 3}>
                  <div>
                    <Link to={providerBaseRoutes.dashboard.path}>
                      <div className={classes.logoImage + " Sidebar-logoImage"}>
                        <img
                          src={this.state.logo}
                          alt="logo"
                          className={classes.img}
                        />
                      </div>
                    </Link>
                    <Link to={providerBaseRoutes.dashboard.path}>
                      <div>
                        <HomeIcon className="HomeLink" />
                      </div>
                    </Link>
                  </div>
                </Box>
                {this.state.providerUserUrl &&
                  this.state.providerUserUrl == "patientListProvider" && (
                    <Box width={1 / 3} className="text-center">
                      <div className="referrals-toggle">
                        <Link
                          href={
                            basePath + providerUserBaseRoutes.patientList.path
                          } ///${user.username}
                          to={
                            basePath + providerUserBaseRoutes.patientList.path
                          } ///${user.username}
                          className={
                            this.state.referralType == "dashboard"
                              ? "link1 link2 active-button"
                              : "link1 link2 inactive-button"
                          }
                          onClick={(event) => this.selectReferrals("dashboard")}
                        >
                          Dashboard
                        </Link>
                        <Link
                          href={
                            basePath + providerUserBaseRoutes.patientList.path
                          } ///${user.username}
                          to={
                            basePath + providerUserBaseRoutes.patientList.path
                          } ///${user.username}
                          className={
                            this.state.referralType == "all"
                              ? "link1 link2 active-button"
                              : "link1 link2 inactive-button"
                          }
                          onClick={(event) => this.selectReferrals("all")}
                        >
                          All Referrals
                        </Link>
                      </div>
                    </Box>
                  )}
                <Box width={1 / 3}>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    className="right-tabs"
                  >
                    <Box p={1}>
                      <div className="tab-link-1">
                        {/* <span>{this.state.providerName}</span> */}
                      </div>
                    </Box>

                    <Box p={1}>
                      <div className="tab-link-1">
                        <Link
                          href={basePath + dpBaseRoutes.notification.path}
                          to={basePath + dpBaseRoutes.notification.path}
                        >
                          <span>Notifications</span>
                          {this.state.newNotification && (
                            <Icon className="fa fa-circle" aria-hidden="true" />
                          )
                          // <div>asdsa</div>
                          }
                        </Link>
                      </div>
                    </Box>
                    <Box p={1}>
                      <div className="tab-link-3">
                        <Button
                          onClick={this.handleToggleNotification}
                          buttonRef={(node) => {
                            this.anchorNotification = node;
                          }}
                          color={"transparent"}
                          aria-owns={
                            openNotifcation
                              ? "notification-menu-list-grow"
                              : null
                          }
                          aria-haspopup="true"
                          className="buttonlinkHeader"
                        >
                          <span className={"name-text"}>
                            {this.state.providerName}
                          </span>{" "}
                          <Icon
                            className="fa fa-caret-down"
                            aria-hidden="true"
                          />
                          <span
                            className={
                              classes.notifications +
                              " AdnetworkSelect-notifications count"
                            }
                          >
                            {adNetworkJson ? adNetworkJson.length : 0}
                          </span>
                        </Button>
                        <Poppers
                          open={openNotifcation}
                          anchorEl={this.anchorNotification}
                          transition
                          disablePortal
                          className={
                            classNames({
                              [classes.popperClose]: !openNotifcation,
                            }) +
                            " " +
                            classes.popperNavXX +
                            " AdnetworkSelect-popperNav"
                          }
                          style={{ zIndex: "9999999" }}
                        >
                          {({ TransitionProps, placement }) => (
                            <Grow
                              {...TransitionProps}
                              id="notification-menu-list-grow"
                              style={{
                                transformOrigin:
                                  placement === "bottom"
                                    ? "center top"
                                    : "center bottom",
                              }}
                            >
                              <Paper className="user-menu-box">
                                <ClickAwayListener
                                  onClickAway={this.handleCloseNotification}
                                >
                                  <MenuList role="menu">
                                    <MenuItem>
                                      <Link
                                        href={
                                          basePath +
                                          providerBaseRoutes.profile.path
                                        } ///${user.username}
                                        to={
                                          basePath +
                                          providerBaseRoutes.profile.path
                                        } ///${user.username}
                                        onClick={this.handleCloseNotification}
                                        className="menu-a"
                                      >
                                        <span>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                          >
                                            <path
                                              id="user-circle_2_"
                                              data-name="user-circle (2)"
                                              d="M8,8A8,8,0,0,0,8,24a8.109,8.109,0,0,0,7.392-4.936A7.6,7.6,0,0,0,16,16,8,8,0,0,0,8,8Zm0,3.1a2.839,2.839,0,1,1-2.839,2.839A2.839,2.839,0,0,1,8,11.1Zm0,11.1a6.182,6.182,0,0,1-4.726-2.2,3.6,3.6,0,0,1,3.177-1.929.789.789,0,0,1,.229.035A4.271,4.271,0,0,0,8,18.323,4.255,4.255,0,0,0,9.319,18.1a.789.789,0,0,1,.229-.035,3.6,3.6,0,0,1,3.177,1.929A6.182,6.182,0,0,1,8,22.194Z"
                                              transform="translate(0 -8)"
                                              fill="#333"
                                            />
                                          </svg>
                                        </span>
                                        <span>Profile</span>
                                      </Link>
                                    </MenuItem>
                                    <MenuItem>
                                      <Link
                                        to={{
                                          pathname:
                                            "https://maxmrj.com/support/",
                                        }}
                                        onClick={this.handleCloseNotification}
                                        className="menu-a"
                                        target={"_blank"}
                                        rel={"noopener noreferrer"}
                                      >
                                        <span>
                                          <i className="fa fa-user-plus"></i>
                                        </span>
                                        <span>Support</span>
                                      </Link>
                                    </MenuItem>
                                    <MenuItem>
                                      <Link
                                        to={{
                                          pathname:
                                            "https://maxmrj.com/privacy-policy/",
                                        }}
                                        onClick={this.handleCloseNotification}
                                        className="menu-a"
                                        target={"_blank"}
                                        rel={"noopener noreferrer"}
                                      >
                                        <span>
                                          <i className="fa fa-file-text-o"></i>
                                        </span>
                                        <span>Privacy Policy</span>
                                      </Link>
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        this.openBaaHandler("open");
                                      }}
                                    >
                                      <Link to="#" className="menu-a">
                                        <span>
                                          <i className="fa fa-file-text-o"></i>
                                        </span>
                                        <span>BAA Policy</span>
                                      </Link>
                                    </MenuItem>
                                    <MenuItem
                                      onClick={this.handleCloseNotification}
                                    >
                                      <Link
                                        to="#"
                                        onClick={this.handleLogout}
                                        className="menu-a"
                                      >
                                        <span>
                                          <i className="fa fa-power-off"></i>
                                        </span>
                                        <span>Logout</span>
                                      </Link>
                                    </MenuItem>
                                  </MenuList>
                                </ClickAwayListener>
                              </Paper>
                            </Grow>
                          )}
                        </Poppers>
                      </div>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Hidden>
            <Hidden className=" admin-h" smUp implementation="css">
              <Box>
                <MenuList role="menu">
                  <Link
                    href={basePath + providerBaseRoutes.dashboard.path}
                    to={basePath + providerBaseRoutes.dashboard.path}
                    onClick={this.props.handleDrawerToggle}
                    className="menu-a"
                  >
                    <span>
                      <HomeIcon className="HomeLink" />
                    </span>
                    <span>Home</span>
                  </Link>
                  <Link
                    href={basePath + providerBaseRoutes.profile.path} ///${user.username}
                    to={basePath + providerBaseRoutes.profile.path} ///${user.username}
                    onClick={this.props.handleDrawerToggle}
                    className="menu-a"
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                      >
                        <path
                          id="user-circle_2_"
                          data-name="user-circle (2)"
                          d="M8,8A8,8,0,0,0,8,24a8.109,8.109,0,0,0,7.392-4.936A7.6,7.6,0,0,0,16,16,8,8,0,0,0,8,8Zm0,3.1a2.839,2.839,0,1,1-2.839,2.839A2.839,2.839,0,0,1,8,11.1Zm0,11.1a6.182,6.182,0,0,1-4.726-2.2,3.6,3.6,0,0,1,3.177-1.929.789.789,0,0,1,.229.035A4.271,4.271,0,0,0,8,18.323,4.255,4.255,0,0,0,9.319,18.1a.789.789,0,0,1,.229-.035,3.6,3.6,0,0,1,3.177,1.929A6.182,6.182,0,0,1,8,22.194Z"
                          transform="translate(0 -8)"
                          fill="#333"
                        />
                      </svg>
                    </span>
                    <span>Profile</span>
                  </Link>
                  <Link
                    href={basePath + dpBaseRoutes.notification.path}
                    to={basePath + dpBaseRoutes.notification.path}
                    onClick={this.props.handleDrawerToggle}
                  >
                    <span>Notifications</span>
                    {this.state.newNotification && (
                      <Icon className="fa fa-circle" aria-hidden="true" />
                    )}
                  </Link>
                  <Link to="#" onClick={this.handleLogout} className="menu-a">
                    <span>
                      <i className="fa fa-power-off"></i>
                    </span>
                    <span>Logout</span>
                  </Link>
                </MenuList>
              </Box>
            </Hidden>
          </div>
        )}
      </div>
    );
  }
}
AdminNavbarLinksClass.propTypes = {
  classes: PropTypes.object,
  updateUserInfo: PropTypes.func,
};
const AdminNavbarLinks = connect(mapStateToProps)(AdminNavbarLinksClass);
export default withStyles(headerLinksStyle)(AdminNavbarLinks);
