/*!

This File contain all route that will use in ./src/index.js & in navigations
*/
import blur_image from "assets/img/darkgr.png";
import logo from "assets/img/asglogo.png";
import {
  ADNETWORK_ID_SLUG,
  CAMPAIGN_ID_SLUG,
  ADVERTISEMENT_ID_SLUG,
} from "__helpers/constants";

// export const basePath = "/ab/asg";
//export const basePath = "/maxmrj";
export const basePath = "";

export const baseRoutes = {
  login: {
    path: `${basePath}/login`,
    pathName: "Login",
  },
  // signup: {
  //   path: `${basePath}/signup`,
  //   pathName: "Signup "
  // },
  forgotPassword: {
    path: `${basePath}/forgot-password`,
    pathName: "Forgot Password",
  },
  resetPassword: {
    path: `${basePath}/reset-password`,
    pathName: "Reset Password",
  },
  pcc3LeggedAuth: {
    path: `${basePath}/pccauth`,
    pathName: "PCC Auth",
  },
  admin: {
    path: "/",
    pathName: "",
  },
  dashboard: {
    path: `${basePath}/facility`,
    pathName: "Facility",
    useLink: `/facility`,
  },
  facilityList: {
    path: `/facility`,
    pathName: "Facility",
    useLink: "/facility",
  },
  profile: {
    path: "/profile",
    pathName: "Profile",
  },
  dischargePlanner: {
    path: `/discharge-planner`,
    pathName: "Discharge Planner",
  },
  provider: {
    path: `/providers`,
    pathName: "Providers",
  },
  // providerOrganizationDetails: {
  //   path: `/provider/organization-details`,
  //   pathName: "Providers organization details",
  // },
  providerProfile: {
    path: `/provider/profile`,
    pathName: "Providers organization details",
  },
  providerUser: {
    path: `/provider/token`,
    pathName: "Providers organization details",
  },
  providerUsers: {
    path: `/provider-users`,
    pathName: "Providers Users",
  },
  referralReports: {
    path: `/referral-reports`,
    pathName: "Referral Reports",
  },
  invoice: {
    path: `/invoice`,
    pathName: "Invoice",
  },
  setting: {
    path: `/setting`,
    pathName: "Setting",
  },
  notification: {
    path: `/notification`,
    pathName: "Notification",
    useLink: "/notification",
  },
  terms: {
    path: `/terms`,
    pathName: "Terms of Service",
    useLink: "/terms",
  },
  baa: {
    path: `/baa`,
    pathName: "Business Associate Agreement",
    useLink: "/baa",
  },
};

export const dpBaseRoutes = {
  admin: {
    path: "/",
    pathName: "",
  },
  dashboard: {
    path: `${basePath}/patientList`,
    pathName: "Patient List",
    useLink: `/patientList`,
  },
  patientList: {
    path: `/patientList`,
    pathName: "Patient List",
    useLink: "/patientList",
  },
  ReferralReportsDischargePlanner: {
    path: `/ReferralReportsDischargePlanner`,
    pathName: "Referral Report",
    useLink: "/ReferralReportsDischargePlanner",
  },
  TaskTemplate: {
    path: `/TaskTemplate`,
    pathName: "Task Template",
    useLink: "/TaskTemplate",
  },
  singlePatientInfo: {
    path: `/patientInfo`,
    pathName: "Patient List",
    useLink: "/patientInfo",
  },
  calendar: {
    path: `/calendar`,
    pathName: "Calendar",
    useLink: "/calendar",
  },
  notification: {
    path: "/notifications",
    pathName: "Notification",
    useLink: "/notifications",
  },
  profile: {
    path: "/profile",
    pathName: "Profile",
  },
  bundlePatient: {
    path: "/bundle-patient",
    pathName: "BundlePatient",
    useLink: "/bundle-patient",
  },
  pcc3LeggedAuth: {
    path: `/pccauth`,
    pathName: "PCC Auth",
    useLink: "/pccauth",
  },
};
export const providerBaseRoutes = {
  profile: {
    path: "/profile",
    pathName: "Profile",
  },
  admin: {
    path: "/",
    pathName: "",
  },
  dashboard: {
    path: `${basePath}/patientListProvider`,
    pathName: "Provider",
    useLink: `/patientListProvider`,
  },
  patientList: {
    path: `/patientListProvider`,
    pathName: "Provider",
    useLink: "/patientListPtovider",
  },

  notification: {
    path: "/notifications",
    pathName: "Notification",
    useLink: "/notifications",
  },
};
export const providerUserBaseRoutes = {
  admin: {
    path: "/",
    pathName: "",
  },
  dashboard: {
    path: `${basePath}/patientListProvider`,
    pathName: "Provider User",
    useLink: `/patientListProvider`,
  },
  patientList: {
    path: `/patientListProvider`,
    pathName: "Provider User",
    useLink: "/patientListPtovider",
  },

  profile: {
    path: "/profile",
    pathName: "Profile",
  },
};
export const projectAssets = {
  blur_image: blur_image,
  brandShortName: "ASG",
  logo: logo,
};

export default baseRoutes;
